//--------------------------------
//共通処理、共通変数定義
//--------------------------------
export default {
    created() {
        console.log('start! from mixins.1111');
    },
    data() {
        //本番サーバー
        const API_URL = 'https://realtime-pos.com/api';
        //テストサーバー
        // const API_URL = 'https://smolt-dev.net/api';
        const ROOT_PATH = '';
        //開発サーバー
        // const API_URL = 'http://localhost:3001'
        
        // const serverUrl = "http://192.168.1.25/";
        const serverUrl = "http://192.168.1.7/";
        const serverUrl8080 = "http://192.168.1.7:8080/";
        // const serverUrl = "http://localhost/";
        // const serverUrl8080 = "http://localhost:8080/";
        const geoserverUrl = serverUrl8080 + "geoserver/"; // geoserverのUrl
        const ecommapUrl = serverUrl + "map/";         // ecommapのUrl
        // const mapModeRireki = 1;
        // const mapModeDelivery = 2;
        return {
            API_URL: API_URL,
            gvROOT_PATH: ROOT_PATH,
            serverUrl: serverUrl,
            //geoserverUrl: "http://192.168.1.25/geoserver/wms/", // geoserverのUrl
            geoserverWmsUrl: geoserverUrl + "wms/",
            geoserverWfsUrl: geoserverUrl + "wfs/",
            geoserverOwsUrl: geoserverUrl + "ows/",
            centerMark: '/image/gps.png',                       //中心位置画像
            centerLonLat: [139.767125, 35.681236],              //初期表示座標
            ecommap: {
                restUrl: ecommapUrl + "rest/",
                cid: '1',    //サイトID
                mapId: '10', //マップID
                apiKey: '9B5DB3351FCCA4F200AC-6BC84D768D77278' //APIキー
            },
            gvDebug:false,
            // gvDebug:true,
            gvMapModeRireki:'1',
            gvMapModeDelivery:'2',
            gvUserClassStaff:'3',
            gvUserClassClient:'1',
            gvUserClassPartner:'2',
            gvUserClassAdmin:'0'
        }
    },
    methods: {
        logging() {
            console.log('logging from mixins.')
        },
        gvLogout() {
            console.log('ログアウト処理（ステート削除、サーバーへ通知）');
            console.log('userid:'+this.$store.state.auth.userid);
            console.log('token:'+this.$store.state.auth.token);
            console.log('user_class:'+this.$store.state.auth.user_class);
            console.log('company_id:'+this.$store.state.auth.company_id);
            console.log('$cookies.isKey(keyName):'+$cookies.isKey('token'));
            this.$cookies.remove("token"); 
            this.$cookies.remove("id"); 
            this.$cookies.remove("user_class"); 
            if (this.$store.state.auth.token != ''){
                this.$store.dispatch(
                    'auth/destroy',
                    {
                    userid: this.$store.state.auth.userid,
                    token: this.$store.state.auth.token,
                    user_class: this.$store.state.auth.user_class,
                    company_id: this.$store.state.auth.company_id
                    }
                );
            }else{
                this.$router.push({name: 'Login', params: {}, query: {}});
            }
        },
        gvFormatDate :(current_datetime)=>{
            let formatted_date = current_datetime.getFullYear() + "/" + (current_datetime.getMonth() + 1) + "/" + current_datetime.getDate() + " " + current_datetime.getHours() + ":" + current_datetime.getMinutes() + ":" + current_datetime.getSeconds();
            return formatted_date;
        },
        // date: 日付オブジェクト
        // format: 書式フォーマット
        gvFormatConvDate :(date, format)=>{
            format = format.replace(/yyyy/g, date.getFullYear());
            format = format.replace(/MM/g, ('0' + (date.getMonth() + 1)).slice(-2));
            format = format.replace(/dd/g, ('0' + date.getDate()).slice(-2));
            format = format.replace(/HH/g, ('0' + date.getHours()).slice(-2));
            format = format.replace(/mm/g, ('0' + date.getMinutes()).slice(-2));
            format = format.replace(/ss/g, ('0' + date.getSeconds()).slice(-2));
            format = format.replace(/SSS/g, ('00' + date.getMilliseconds()).slice(-3));
            return format;
        },

        // 2013-07-08 20:38:08 といった形式を ISO 8601 に変換
        gvReplaceDate :(dateStr)=> {
            const regexp = /^([0-9]{2,4})-([0-1][0-9])-([0-3][0-9])(?:( [0-2][0-9]):([0-5][0-9]):([0-5][0-9]))?$/;
            return dateStr.replace(regexp, (match, year, month, day, hour, minutes, seconds) => {
            return `${year}-${month}-${ day}T${hour}:${minutes}:${seconds}.000+09:00`;
            });
        }        


    },
    computed: {
    }
}