<template>
<modal name="dlglogout" :height="220" :draggable="true" :resizable="true" adaptive="true">
    <div class="modal-header">
      <h5>ログアウト</h5>
    </div>
    <div class="modal-body">
      <p>ログアウトしますか？</p>
      <br>
      <div class="btn-toolbar  mx-auto w-50">
        <div class="btn-group">
          <button v-on:click="this.gvLogout" class="btn btn-sm btn-outline-secondary w-20">はい</button>
        </div>
        <div class="btn-group ml-auto">
          <button v-on:click="hideLogout" class="btn btn-sm btn-outline-secondary w-20">いいえ</button>
        </div>
      </div>
    </div>  
</modal>
</template>

<script>

export default {
  name: 'Logout',  
  data () {
    return {
      userid: '',
      password: ''
    }
  },
  methods: {
    showLogout : function() {
        this.$modal.show('dlglogout');
      },
    hideLogout : function () {
        this.$modal.hide('dlglogout');
    },        
  },
}
</script>



<style>


</style>