<template>
<div id="app">
</div>

</template>

<script>


export default {
  name: 'Redirect',  
  data () {
    return {
      userid: '',
      password: ''
    }

  },
  methods: {
  },
  created: function () {
    //ログアウトしてログイン画面へリダイレクトするのみのコンポーネント
    console.log('logout redirect');
    this.gvLogout();
    this.$router.push({name: 'Login', params: {}, query: {}});
  },
}
</script>


