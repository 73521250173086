<template>
  <div id="app">
        <!--マップコンポーネント表示-->
        <header>
        </header>
        <main style="height:100%;">
          <div class="parent">
            <div class="child3">
              <router-view></router-view>
              <!-- <MapPane  ref="map" v-model="mapData" tagname="div" :screenmode="singlescreen" 
              @notifyResult2Sireidai="notifyResult2Sireidai"
              ></MapPane> -->
            </div>
          </div>
        </main>
  </div>
</template>



<script>
// アコーディオンメニュー
import Vue from 'vue'

export default {
  name: 'app',
  components: {
  },  
  data () {
    return {

    }
  },
  mounted: function() {
    // //Google Map Apiキーを埋め込む
    // this.$nextTick(() => {
    //   // ここでwindowオブジェクトにアクセスできます
    //   console.log(window);
    //   const script = document.createElement('script')
    //   script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBzEw3Qh8UpMkOO_sPbT3wv46gvMtZcyDY&callback=initMap';
    //   script.async = true;
    //   window.initMap = function() {
    //     // JS API is loaded and available
    //   };    
    //   document.head.appendChild(script)
    // });    
  },
}

// // アコーディオンメニュー
// import Vue from 'vue'
// import MapPane from './components/MapPane.vue'
// //import LayerMenu from './components/LayerMenu.vue'
// import Sireidai from './components/Sireidai.vue'


// export default {
//   name: 'app',
//   components: {
//     //LayerMenu,
//     MapPane,
//     Sireidai,
//   },  
//   data () {
//     return {
//       //試験用の変数
//       testInfo:{
//         test_refno:9000,
//         test_lon:139.767125,
//         test_lat:35.681236,
//         test_commandDskNo:1000,
//         test_commandDskNo_org:1000,
//         test_disasterClsNo:2,
//         test_kinshiSelected: 'hashigo',
//         test_carrierTypeNoSelected:'1',
//         test_notifyNo:2000,
//       },

//       showLeftLayerMenu: true,
//       showRightLayerMenu: true,
//       // showDoubleScreen: false,
//       isInterlocking: false,
//       rbtDoubleScreen: "OFF",
//       rbtInterlocking: "OFF",
//       rlayer_button: "",
//       llayer_button: "",
//       rlayerMenuPos: "right",
//       llayerMenuPos: "left",
//       cityName: "住所取得中・・・",
//       mode:"", // 1:シナリオ2 自由地震:3 自由津波
//       freeFlg:false,
//       mapData: {
//         lon:0.0,
//         lat:0.0,
//         lonValue: "緯度 =            ",
//         latValue: "経度 =            ",
//         scaleValue: "",
//         zoomValue: 17,
//         cityName: "",
//         comment_q: "",
//         time: "",
//         time30: "",
//         depth: "",
//         si: "",
//         pl: "",
//         height: "",
//         layerMenuPos: "single",
//         dicBaseLayers:"",
//         dicLayers:"",
//         kenFlg:true,
//       },
//       dicBaseLayers:{},
//       dicLayers:{},
//       showMapSub:false,
//       singlescreen:'singlescreen',

//     }
//   },
//   created: function() {

//   },
//   mounted: function() {
//     //URLクエリーから自由選択画面か否かを取得
//     if ((this.$route.query.mode==='1')||(this.$route.query.mode==='2')||(this.$route.query.mode==='3')){
//       this.mode=this.$route.query.mode;
//     }else{
//       this.mode='1';
//     }
//   },
//   watch: {
//       //地図の緯度経度の変更を監視する 
//       //左地図
//       'mapData.lon': function () {
//         // if ((this.showDoubleScreen)&&(this.isInterlocking)){
//         //   console.log('監視：'+this.mapData.lon)
//         //   if (this.showDoubleScreen){
//         //     this.$refs.mapsub.centerMove(this.mapData.lon,this.mapData.lat);
//         //   }
//         // }
//       },
//       'mapData.lat': function () {
//         // if ((this.showDoubleScreen)&&(this.isInterlocking)){
//         //   console.log('監視：'+this.mapData.lat)
//         //   if (this.showDoubleScreen){
//         //     this.$refs.mapsub.centerMove(this.mapData.lon,this.mapData.lat);
//         //   }
//         // }
//       },
//       'mapData.zoomValue': function () {
//         console.log('監視：'+this.mapData.zoomValue)
//           // if ((this.showDoubleScreen)&&(this.isInterlocking)){
//           //   this.$refs.mapsub.setZoom(this.mapData.zoomValue);
//           // }
//       },
//   },

//   methods: {
//     //試験用パラメータ受け取り
//     testChange: function(testInfo){
//       this.testInfo = testInfo;
//       console.debug('testChange this.test_refno'+this.testInfo.test_refno);
//       console.debug('testChange this.test_lon'+this.testInfo.test_lon);
//       console.debug('testChange this.test_lat'+this.testInfo.test_lat);
//       console.debug('testChange this.test_commandDskNo'+this.testInfo.test_commandDskNo);
//       console.debug('testChange this.test_disasterClsNo'+this.testInfo.test_disasterClsNo);
//       console.debug('testChange this.test_commandDskNo_org'+this.testInfo.test_commandDskNo_org);
//       console.debug('testChange this.test_notifyNo'+this.testInfo.test_notifyNo);
      

// 　  },
//     //指令台からの通知で地図にポイントを追加する
//     notifySireidaiAddPoint(pointInfo) {
//       console.debug('app add lon:' + pointInfo.lon);
//       console.debug('app add lat:' + pointInfo.lat);
//       console.debug('app add syubetsu:' + pointInfo.syubetsu);
//       console.debug('app add refNo:' + pointInfo.refNo);
//       //地図ポイント追加
//       this.$refs.map.addPoint(pointInfo.syubetsu,pointInfo);
//       //緯度経度へ移動
//       console.debug('moveLonLat  x:' + pointInfo.x);
//       console.debug('moveLonLat  y:' + pointInfo.y);
//       console.debug('moveLonLat  lon:' + pointInfo.lon);
//       console.debug('moveLonLat  lat:' + pointInfo.lat);
//       // this.$refs.map.moveLonLat({ x:pointInfo.x, y:pointInfo.y, iconName:pointInfo.syubetsu });
//       this.$refs.map.moveLonLat({ lon:pointInfo.lon, lat:pointInfo.lat, iconName:pointInfo.syubetsu });
//     },
//     //指令台からの通知で地図にポイントを変更する
//     notifySireidaiUpdatePoint(pointInfo) {
//       console.debug('app add x:' + pointInfo.lon);
//       console.debug('app add y:' + pointInfo.lat);
//       console.debug('app add no:' + pointInfo.no);
//       console.debug('app add syubetsu:' + pointInfo.syubetsu);
//       //地図ポイント追加
//       this.$refs.map.updatePoint(pointInfo.syubetsu,pointInfo);
//       //緯度経度へ移動（車両の時のみ）
//       if (pointInfo.syubetsu=='kinshi'){
//         this.$refs.map.moveLonLat({ lon:pointInfo.lon, lat:pointInfo.lat, iconName:pointInfo.syubetsu });
//       }
//     },
//     //指令台からの通知で地図からポイントを削除する
//     notifySireidaiDeletePoint(pointInfo) {
//       console.debug('app delete no:' + pointInfo.no);
//       console.debug('app delete syubetsu:' + pointInfo.syubetsu);
//       //地図ポイント削除
//       this.$refs.map.deletePoint(pointInfo.syubetsu,pointInfo);
//       //緯度経度へ移動
//       // this.$refs.map.moveLonLat({ x:pointInfo.x, y:pointInfo.y, iconName:pointInfo.syubetsu });
//       // this.$refs.map.moveLonLat({ lon:pointInfo.lon, lat:pointInfo.lat, iconName:pointInfo.syubetsu });
//     },
//     //指令台からの通知で災害終了
//     notifySireidaiSaigaiComplete(pointInfo) {
//       console.debug('app delete refNo:' + pointInfo.refNo);
//       console.debug('app delete syubetsu:' + pointInfo.syubetsu);
//       //地図ポイント削除
//       this.$refs.map.SaigaiComplete(pointInfo.syubetsu,pointInfo);
//       //緯度経度へ移動
//       // this.$refs.map.moveLonLat({ x:pointInfo.x, y:pointInfo.y, iconName:pointInfo.syubetsu });
//       // this.$refs.map.moveLonLat({ lon:pointInfo.lon, lat:pointInfo.lat, iconName:pointInfo.syubetsu });
//     },
//     //指令台からの通知で支援終了
//     notifySireidaiShienComplete(pointInfo) {
//       console.debug('app delete no:' + pointInfo.refNo);
//       console.debug('app delete syubetsu:' + pointInfo.syubetsu);
//       //地図ポイント削除
//       this.$refs.map.ShienComplete(pointInfo.syubetsu,pointInfo);
//       //緯度経度へ移動
//       // this.$refs.map.moveLonLat({ x:pointInfo.x, y:pointInfo.y, iconName:pointInfo.syubetsu });
//       // this.$refs.map.moveLonLat({ lon:pointInfo.lon, lat:pointInfo.lat, iconName:pointInfo.syubetsu });
//     },
//     //指令台からのルート検索
//     notifySireidaiRouteSearch(pointInfo) {
//       console.debug('notifySireidaiRouteSearche syubetsu:' + pointInfo.syubetsu);
//       //ルート検索
//       this.$refs.map.RouteSearchDisplay(pointInfo.syubetsu,pointInfo);
//     },
//     //地図から指令台への処理結果通知
//     notifyResult2Sireidai(resultInfo){
//       console.debug('■■地図から指令台への処理結果通知■■■');
//       console.debug('app notifyResult2Sireidai method:' + resultInfo.method);
//       console.debug('app notifyResult2Sireidai result:' + resultInfo.result);
//       console.debug(resultInfo.pointInfo);
//       this.$refs.sireidai.callbackMap(resultInfo);
//     },

//   }

// }
</script>


<style>


</style>

