<template>

<modal name="dlgMessage" :height="220" :draggable="true" :resizable="true" adaptive="true">
    <div class="modal-header">
      <h5>メッセージ</h5>
    </div>
    <div class="modal-body">
      <p>{{ message }}</p>
      <br>
        <div class="btn-toolbar justify-content-center" role="toolbar" aria-label="Toolbar with button groups">
           <div class="btn-group">
            <button v-on:click="hideMessage" class="btn btn-sm btn-outline-secondary w-20">閉じる</button>
          </div>
        </div>
    </div> 
</modal>
</template>

<script>

export default {
  name: 'Message',  
  data () {
    return {
      userid: '',
      password: ''
    }
  },
  props: {
    message: {
      type: String,
      required: true,
    }
  },  
  methods: {
    hideMessage : function () {
        this.$modal.hide('dlgMessage');
    },        
  },
}
</script>



<style>
.modal-dialog {
 
          width: 360px;
 
        }
 
.modal-header {
 
    background-color: #337AB7;
 
    /* padding:16px 16px; */
 
    color:#FFF;
 
    /* border-bottom:2px dashed #337AB7; */
 
 }

</style>