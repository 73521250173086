import axios from 'axios'
require('dotenv').config();
import gv from '../../mixins/globalValiables';

export default {
  namespaced: true,
  actions: {
    async request ({ dispatch, rootState }, { method, url, data, error }) {
      //ヘッダーセット
      const headers = {}
      headers['Content-Type'] = 'application/json'
      console.log('◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆');
      console.log('rootState.auth.token:'+rootState.auth.token);
      console.log('◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆◆');
      if (rootState.auth.token) {
        headers['authorization'] = `${rootState.auth.token}`
        headers['userid'] = rootState.auth.userid
      }
      console.log('headers authorization:'+headers['authorization']);
      console.log('headers userid:'+headers['userid']);
      console.log('gv.data().API_URL:'+`${url}`);
      console.log(`${gv.data().API_URL}${url}`);
      console.log('method:'+method);
      let propertyArray = Object.entries(data);
      propertyArray.forEach(function(element){
        console.log(element);
      });


      //axiosのパラメータセット
      const options = {
        method,
        url: `${gv.data().API_URL}${url}`,
        headers,
        data,
        timeout: 4000
      }

      return axios(options)
        // .then(res => res)
        .then(res => {
          //TODO 試験データがスタッフしかないのでとりあえずログインさせる
          // if (res.data.user_class == '1'){  //スタッフはログイン不可
          //   dispatch(
          //     'message/create',
          //     {errorinfo:{error: 'ログインできません。',redirect: 'login'} },
          //     { root: true }
          //   )
          // }
          return res;
        })
        .catch(err => {
            console.log(err);
            console.log(err.response.status);
            //console.log(error);
              //エラーメッセージ登録
            if (err.response.status == 401){
              dispatch(
                'message/create',
                // { error: 'ID、パスワードに誤りがあります。' },
                //{errorinfo:{error: error,redirect: 'login'} },
                // {errorinfo:{error: '再度ログインしてください',redirect: 'login'} },
                {errorinfo:{error: 'ID、パスワードに誤りがあります。',redirect: 'login'} },

                { root: true }
              )
            }else{
              dispatch(
                'message/create',
                {errorinfo:{error: 'その他エラー。',redirect: 'login'} },
                { root: true }
              )
            }
            //呼び出し元にエラースロー
            throw err;
        })
    },
    async post ({ dispatch }, requests) {
      requests.method = 'post'
      return dispatch('request', requests)
    },
    async delete ({ dispatch }, requests) {
      requests.method = 'delete'
      return dispatch('request', requests)
    },
    async get ({ dispatch }, requests) {
      requests.method = 'get'
      return dispatch('request', requests)
    }
  }
}