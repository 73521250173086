export default {
    namespaced: true,
    state: {
      error: '',
      redirect: ''
    },
    mutations: {
      create (state, data) {
        state.error = data.errorinfo.error;
        state.redirect = data.errorinfo.redirect;
        console.log('##################');
        console.log(data.errorinfo.error);
        console.log(data.errorinfo.redirect);
        console.log('##################');

      },
      destroy (state) {
        state.error = '';
        state.redirect = ''; 
      }
    },
    actions: {
      create1({ commit, dispatch }, data) {
        commit('create', data) //ステートにセーブ
      },

      create(context, payload) {
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
        console.log(payload);
        console.log('%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%');
        context.commit('create', payload) //ステートにセーブ
      },
      destroy(context) {
        context.commit('destroy')
      },
      // //ログイン＆セーブ
      // create ({ commit, dispatch }, data) {
      //   commit('create', res.data);
      // },
      //ログオフ＆クリア
      // destroy ({ commit, dispatch }, data) {
      //   commit('destroy', res.data);
      // }
    }
  }