//storeのなかでrouter.pushする為、import
import router from "../../route.js"
export default {
    namespaced: true,
    state: {
      reloadMapFlg: false,
      googleLayerDisp:false,
      userid: '',
      token: '',
      user_class: '',
      user_name: '',
      company_id: '',
      pcAdminInterval: '4000',
      pcAdminStaffInterval: '7000',
      pcAdminCookieExpireTimes: '700min',
    },
    mutations: {
      create (state, data) {
        console.log('■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■');
        console.log('data.token:'+data.token);
        console.log('data.userid:'+data.id);
        console.log('data.user_class:'+data.user_class);
        console.log('data.user_name:'+data.user_name);
        console.log('data.company_id:'+data.company_id);
        console.log('■■■■■■■■■■■■■■■■■■■■■■■■■■■■■■');
        // state.googleLayerDisp = false;
        state.token = data.token;
        state.userid = data.id;
        state.user_class = data.user_class;
        state.user_name = data.user_name;
        state.company_id = data.company_id;

      },
      setGoogleLayer (state, data) {
        state.googleLayerDisp = data.googleLayerDisp;
      },
      setReloadMapFlg (state, data) {
        state.reloadMapFlg = data.reloadMapFlg;
      },
      destroy (state) {
        console.log('destroy start!!');
        console.log(state.token);
        console.log(state.userid);
        state.reloadMapFlg = false;
        state.googleLayerDisp = false;
        state.token = '';
        state.userid = '';
        state.user_class = '';
        state.user_name = '';
        state.company_id = '';
        state.pcAdminInterval = '';
        state.pcAdminStaffInterval = '';
        state.pcAdminCookieExpireTimes = '';
          console.log('destroy end!!');
      },

    },
    actions: {
      //setGoogleLayer
      setReloadMapFlg ({ commit, dispatch }, data) {
        commit('setReloadMapFlg', data) //ステートにセーブ
      },
      //setGoogleLayer
      setGoogleLayerDisp ({ commit, dispatch }, data) {
        commit('setGoogleLayer', data) //ステートにセーブ
      },
      //ログイン＆セーブ
      tokencheck ({ commit, dispatch }, data) {
        dispatch(
          'http/get',
          { url: '/auth/tokencheck', data, error: '認証エラー' },
          { root: true }
        ).then(res => commit('create', res.data)) //ステートにセーブ
          .catch(err => err)
      },
      //ログイン＆セーブ
      tokencheck_intora ({ commit, dispatch }, data) {
        dispatch(
          'http/get',
          { url: '/auth/tokencheck', data, error: '認証エラー' },
          { root: true }
        ).then(res => {
          commit('create', res.data);
          dispatch('message/destroy')
          var nowstaffcd = data.nowstaffcd;
          console.log(`/Staff/${nowstaffcd}`);
          router.push(`/Staff/${nowstaffcd}`, () => {})
          // $store.dispatch('/Staff', () => {})
          //dispatch('/Staff', () => {});
          // dispatch(
          //   'message/create',
          //   {errorinfo:{error: '',redirect: 'Staff'} },
          //   { root: true }
          // );
      }) //ステートにセーブ
          .catch(err => {
            console.log('######################################');
            console.log('#######ログイン強制画面遷移!!##########');
            console.log(err);
            console.log('######################################');
            //ログイン画面遷移
            dispatch(
              'message/create',
              {errorinfo:{error: 'ログインに失敗しました（tokenエラー）',redirect: 'login'} },
              { root: true }
            );
          })
      },
      //ログイン＆セーブ
      create ({ commit, dispatch }, data) {
        dispatch(
          'http/post',
          { url: '/auth/login', data, error: '認証エラー' },
          { root: true }
        ).then(res => commit('create', res.data)) //ステートにセーブ
          .catch(err => err)
      },
      //ログオフ＆クリア
      destroy ({ commit, dispatch }, data) {
        dispatch(
          'http/delete',
          { url: '/auth/logout', data },
          { root: true }
        ).then(res => commit('create', res.data)) //ステートをクリア
          .catch(err => err)
          // logout anyway ...
          .finally(res => commit('destroy'))
      },
      //認証チェック
      check ({ commit, dispatch }, data) {
        dispatch(
          'http/get',
          { url: '/auth/check', data },
          { root: true }
        ).then(res => commit('create', res.data)) //ステートをクリア
          .catch(err => err)
      }
    }
  }