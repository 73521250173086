import Vue from 'vue'
import VueRouter from "vue-router"
import App from "./App.vue"
import Login from './components/Login.vue'
import Redirect from './components/Redirect.vue'
import Staff from './components/Staff.vue'
// import MapPane from './components/MapPane.vue'
import AppMap from "./AppMap.vue"

Vue.use(VueRouter)


const router = new VueRouter({
    mode: 'history',
    // base: '/rpkanri/',    
    // routes: []
    routes: [
        {
            //地図画面
            path: '/AppMap',
            name: 'AppMap',
            component: AppMap,
            props: true,
            meta: {
                isPublic: true
            }
        },
        {
            //地図画面イントラから遷移
            path: '/AppMap/:nowstaffcd?',
            name: 'AppMap',
            component: AppMap,
            props: true,
            meta: {
                isPublic: true
            }
        },
        {
            //ログイン画面
            path: '/',
            name: 'Login',
            component: Login,
            meta: {
                isPublic: true
            }
        },
        {
            //Redirect
            path: '/Redirect',
            name: 'Redirect',
            component: Redirect,
            meta: {
                isPublic: true
            }
        },
        {
            //配布担当者画面
            path: '/Staff',
            name: 'Staff',
            component: Staff,
            meta: {
                isPublic: true
            }
        },
        {
            //配布担当者画面イントラから遷移
            path: '/Staff/:nowstaffcd?',
            name: 'Staff',
            component: Staff,
            meta: {
                isPublic: true
            }
        },
    ]
})

// router.beforeEach((to, from, next) => {
//   next()
// //   if (to.matched.some(page => page.meta.isPublic) || Store.state.auth.token) {
// //     next()
// //   } else {
// //     next('/login')
// //   }
// })
router.beforeEach((to, from, next) => {
    if (to.matched.some(page => page.meta.isPublic) || Store.state.auth.token) {
      next()
    } else {
      next('/login')
    }
  })
  


export default router



// import Vue from 'vue'
// import Router from 'vue-router'
// import App from "./App.vue"
// // components
// // import Top from '@/components/top.vue'
// import Login from '@/components/login.vue'
// // import Logout from '@/components/logout.vue'
// // import Error from '@/components/error.vue'

// // store
// import Store from '@/store/index.js'

// Vue.use(Router)

// const router = new Router({
//   routes: [
//     // {
//     //   path: '/',
//     //   name: 'top',
//     //   component: Top,
//     //   meta: {
//     //     isPublic: true
//     //   }
//     // },
//     {
//       path: '/login',
//       name: 'login',
//       component: Login,
//       meta: {
//         isPublic: true
//       }
//     },
//     // {
//     //   path: '/logout',
//     //   name: 'logout',
//     //   component: Logout
//     // },
//     // {
//     //   path: '/error',
//     //   name: 'error',
//     //   component: Error
//     // },
//     // {
//     //   path: '*',
//     //   redirect: '/error'
//     // }
//   ]
// })

// router.beforeEach((to, from, next) => {
//   if (to.matched.some(page => page.meta.isPublic) || Store.state.auth.token) {
//     next()
//   } else {
//     next('/login')
//   }
// })

// export default router