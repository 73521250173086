<template>


<modal name="MessageDialog" :draggable="true" :resizable="true" :width="400" :height="300">
    <div class="modal-header">
      <h5>エラー</h5>
    </div>
    <div class="modal-body">
      <p>{{errorMessage}}</p>
      <br>
      <div class="btn-toolbar  mx-auto">
        <div class="col text-center">
          <button v-on:click="hideDialog" class="btn btn-sm btn-outline-secondary w-20">OK</button>
        </div>

      </div>
    </div>  
</modal>
</template>

<script>

export default {
  name: 'MessageDialog',  
  data () {
    return {
      userid: '',
      password: ''
    }
  },
  computed: {
    errorMessage () {
      return this.$store.state.message.error
    }
  },
  methods: {
    hideDialog : function () {
        console.log('#################');
        console.log(this.$store.state.message.error);
        console.log(this.$store.state.message.redirect);
        console.log('#################');

        if (this.$store.state.message.redirect=='login'){
          this.gvLogout();
          this.$router.push({name: 'Login', params: {}, query: {}});
        }else{
          this.$modal.hide('MessageDialog');
        }

    },        
  },
}
</script>



<style>


</style>