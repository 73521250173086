<template>
  <div id="app">
  
<div class="login-form">
        <h2 class="text-center">ログイン</h2>       
        <article v-show="errorMessage" class="alert alert-danger">
          <div class="message-body">
            {{ errorMessage }}
          </div>
        </article>

        <div class="form-group">
            <ValidationProvider name="userid" rules="required|max:64" v-slot="{ errors }">
              <input class="form-control" type="userid" placeholder="ユーザーID" v-model="userid" autofocus="" name="userid">
              <span>{{ errors[0] }}</span>
            </ValidationProvider>

        </div>
        <div class="form-group">
            <ValidationProvider name="password" rules="required|min:6|max:64" v-slot="{ errors }">
              <input class="form-control" type="password" placeholder="パスワード" v-model="password"  maxlength="64" name="password">
              <span>{{ errors[0] }}</span>
            </ValidationProvider>
        </div>
        <div class="form-group">
            <button class="btn btn-primary btn-block" @click="login()" >ログイン</button>
        </div>
        <div class="clearfix">
            <label class="float-left form-check-label"><input type="checkbox" v-model="checkedlogin"> ログインしたままにする</label>
            <!-- <a href="#" class="float-right">Forgot Password?</a> -->
        </div>
        <p/>        
        <h10 class="text-center">Ver 0.8.3</h10>       
    <!-- <p class="text-center"><a href="#">Create an Account</a></p> -->
</div>
  </div>
  <!-- <div id="app">
    <section class="hero is-light is-fullheight">
      <div class="hero-body">
        <div class="container has-text-centered">
          <article v-show="errorMessage" class="message is-warning">
            <div class="message-body">
              {{ errorMessage }}
            </div>
          </article>
          <div class="column is-4 is-offset-4">
            <div class="box">
               <div class="field">
                 <div class="control">
                   <input class="input is-large" type="userid" placeholder="ユーザーID" v-model="userid" autofocus=""  name="userid">
                 </div>
              </div>
              <div class="field">
                <div class="control">
                  <input class="input is-large" type="password" placeholder="パスワード" v-model="password"  maxlength="20" name="password">
                </div>
              </div>
              <div class="field">
                <label class="checkbox">
                <input type="checkbox">
                ログインしたままにする
                </label>
               </div>
               <button class="button is-block is-info is-large is-fullwidth" @click="login()" >ログイン</button>
              </div>
            </div>
          </div>
        </div>
    </section>
  </div> -->

</template>

<script>
import { ValidationProvider, extend,localize  } from "vee-validate";
import { required,max,min,email } from 'vee-validate/dist/rules';
import ja from "vee-validate/dist/locale/ja.json";
import gv from '../mixins/globalValiables';
import axios from 'axios';

extend('required', required)
extend('max', max)
extend('min', min)
extend('email', email)
localize('ja', ja);
localize({
  ja: {
    names: {
      userid: "ユーザーID",
      password: "パスワード",
    },
   },
});


export default {
  data () {
    return {
      userid: '',
      password: '',
      checkedlogin: false,
      header: {},
    }
  },

 components: {
    ValidationProvider,
  },
  methods: {
    login () {
      //認証トークン生成
      this.$store.dispatch(
        'auth/create',
        {
        id: this.userid,
        pass: this.password
        }
      )
    }
  },
  mounted() {

  },
  computed: {
    isValidated () {
        //オブジェクトが持つプロパティの 名前の配列
        return Object.keys(this.fields).every(k => this.fields[k].validated) &&
        Object.keys(this.fields).every(k => this.fields[k].valid)
    },
    token () {
      return this.$store.state.auth.token
    },
    errorMessage () {
      return this.$store.state.message.error
    }
  },
  created: function () {
    //設定ファイルを読み込む
    //http header設定
    this.header = {}
    this.header['Content-Type'] = 'application/json'
    const options = {
        method:'post',
        url: `${gv.data().API_URL}/maps/config`,
        headers:this.header,
        timeout: 4000
    }
    let that = this;
    axios(options)
    .then(response => {
        if (response.status != 200){
            console.log('設定ファイルresponseエラー'+response.status);
        }
        var jsonObject = response.data;
        console.log(jsonObject);
        if (jsonObject.length != 0){
          console.log(jsonObject['pcAdminInterval']);
          console.log(jsonObject['pcAdminStaffInterval']);
          console.log(jsonObject['pcAdminCookieExpireTimes']);
          that.$store.state.auth.pcAdminInterval = jsonObject['pcAdminInterval'];
          that.$store.state.auth.pcAdminStaffInterval = jsonObject['pcAdminStaffInterval'];
          that.$store.state.auth.pcAdminCookieExpireTimes = jsonObject['pcAdminCookieExpireTimes'];
        }
    })
    .catch(err => {
        console.log('================');
        console.log(err);
        console.log('================');
        //強制ログアウト（イントラからtokenチェックエラーは、ここでチェック）
        that.gvLogout();
        that.$route.query.token = '';
        //ログイン画面遷移
        that.$store.dispatch(
          'message/create',
          {errorinfo:{error: 'ログインに失敗しました（configエラー）',redirect: 'login'} },
          { root: true }
        );
        that.$router.push({name: 'Login', params: {}, query: {}});
    })
    .finally(() => {
    });


    // {"id":"100","user_class":"1","user_name":"税理士法人香陵総合会計事務所","token":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjEwMCIsInVzZXJfY2xhc3MiOiIxIiwidXNlcl9uYW1lIjoi56iO55CG5aOr5rOV5Lq66aaZ6Zm157eP5ZCI5Lya6KiI5LqL5YuZ5omAIiwic2Vzc2lvbl9pZCI6IjZ0bWw1aGZ2bjN1ZmVndDI0MTFra2Jpa3U2IiwiaWF0IjoxNjY1ODYyODc5LCJleHAiOjE2NjU5MDYwNzl9.m84VMs8FKYAf3QSAfuYiOFuUDG7Hx4cjeHGn51rTPhA","session_id":"NnRtbDVoZnZuM3VmZWd0MjQxMWtrYmlrdTY="}
    let wknowstaffcd = this.$route.query.nowstaffcd != undefined ? this.$route.query.nowstaffcd : '';
    let wktoken = this.$route.query.token != undefined ? this.$route.query.token : '';
    // let wkid = this.$route.query.id != undefined ? this.$route.query.id : '';
    // let wkuser_class = this.$route.query.user_class != undefined ? this.$route.query.user_class : '';
    if (wktoken !=''){
      this.$cookies.config(60 * 60,''); //1時間
      this.$cookies.set('token', wktoken);
      // this.$cookies.set('id', wkid);
      // this.$cookies.set('user_class', wkuser_class);

      //       const token = req.headers.authorization;
      this.$store.state.auth.token = wktoken;
      //this.$store.state.auth.userid = this.$cookies.get('id');
      // var that = this;
      this.$store.dispatch(
        'auth/tokencheck_intora',
        {
          nowstaffcd:wknowstaffcd 
        }
      )
      .then(res => {
      })
      .catch(err => {
        // console.log('######################################');
        // console.log(err);
        // console.log('######################################');
        // //ログイン画面遷移
        // dispatch(
        //   'message/create',
        //   {errorinfo:{error: '再ログインしてください',redirect: 'login'} },
        //   { root: true }
        // );
        // console.log('#######gvLogout##########');
        // that.gvLogout();
        // that.$route.query.token = '';
      })
    }
    else{

      console.log('login created start!!:'+this.$route.query.staffcd);
      //クッキーにtokenがある場合は、token認証で自動ログインする
      if ($cookies.isKey('token')){
        this.$store.state.auth.token = this.$cookies.get('token');
        this.$store.state.auth.userid = this.$cookies.get('id');
        this.$store.state.auth.user_class = this.$cookies.get('user_class');
        this.$store.state.auth.company_id = this.$cookies.get('company_id');

        console.log('========================');
        console.log('created cookie token:'+this.$cookies.get('token'));
        console.log('========================');
        //認証トークンのみでログイン
        this.$store.dispatch(
          'auth/tokencheck',
          {
          }
        )
      }


      this.$store.dispatch('message/destroy')
      // 既に認証済みの場合
      if (this.$store.state.auth.token) {
        console.log('login auth.token:'+this.$store.state.auth.token);
        console.log('login auth.userid:'+this.$store.state.auth.userid);
        console.log('login auth.user_class:'+this.$store.state.auth.user_class);
        console.log('login auth.company_id:'+this.$store.state.auth.company_id);
        console.log('/Staff');
        this.$router.push({name: 'Staff', params: {}, query: {}});
      }else{
        console.log('login created no token!!');
      }
      
    }

  },
  watch: {
    // トークンを監視してトークンに値が入ったら認証済みとする
    // token (newToken) {
    token (newValue, oldValue){
      console.log('login watch newValue:'+newValue);
      console.log('login watch oldValue:'+oldValue);
      console.log('login watch auth.token:'+this.$store.state.auth.token);
      console.log('login watch auth.userid:'+this.$store.state.auth.userid);
      console.log('login watch auth.user_class:'+this.$store.state.auth.user_class);
      console.log('login watch auth.company_id:'+this.$store.state.auth.company_id);
      console.log('login watch auth.pcAdminInterval:'+this.$store.state.auth.pcAdminInterval);
      console.log('login watch auth.pcAdminStaffInterval:'+this.$store.state.auth.pcAdminStaffInterval);
      console.log('login watch auth.pcAdminCookieExpireTimes:'+this.$store.state.auth.pcAdminCookieExpireTimes);

      if (newValue == 'ログインに失敗しました（tokenエラー）'){
        return;
      }else{

      }

      //tokenをセッションに保存
      if (this.checkedlogin){
        this.$cookies.config(60 * 60,''); //1時間
        this.$cookies.set('token', this.$store.state.auth.token);
        this.$cookies.set('id', this.$store.state.auth.userid);
        this.$cookies.set('user_class', this.$store.state.auth.user_class);
        this.$cookies.set('company_id', this.$store.state.auth.company_id);
        console.log('$cookies.isKey(keyName):'+$cookies.isKey('token'));
        console.log('cookie token:'+this.$cookies.get('token'));
      }

      //認証されている時のみスタッフ画面へ遷移
      if (this.$store.state.auth.userid !=''){
        //user_classにより遷移先切り替え
          console.log('this.gvDebug='+this.gvDebug);
        // クライアントの場合   ：地図画面自 user_class_
        if (this.$store.state.auth.user_class ==this.gvUserClassClient){
          console.log('/Staff');
          this.$router.push({name: 'Staff', params: {}, query: {}});
        }
        // パートナーの場合     ：地図画面
        if (this.$store.state.auth.user_class ==this.gvUserClassPartner){
          console.log('/Staff');
          this.$router.push({name: 'Staff', params: {}, query: {}});
        }
        // 管理者の場合         ：担当者一覧
        if (this.$store.state.auth.user_class ==this.gvUserClassAdmin){
          console.log('/Staff');
          this.$router.push({name: 'Staff', params: {}, query: {}});
        }
        // スタッフはログインさせない
        if (this.$store.state.auth.user_class ==this.gvUserClassStaff){
          if (this.gvDebug){
            console.log('/Staff');
            this.$router.push({name: 'Staff', params: {}, query: {}});
          }else{
            this.$store.state.message.error = 'スタッフはログインできません';
          }
        }


      }
    },
  }
}
</script>



<style>
.login-form {
    width: 400px;
    margin: 50px auto;
  	font-size: 15px;
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}
.login-form form {
    margin-bottom: 15px;
    background: #f7f7f7;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
    padding: 30px;
}
.login-form h2 {
    margin: 0 0 15px;
}
.form-control, .btn {
    min-height: 38px;
    border-radius: 2px;
}
.btn {        
    font-size: 15px;
    font-weight: bold;
}

</style>