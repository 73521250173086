<template>
  <div id="app">
  <div class="container" v-show="isDisplay">
    <div>
      <p>
      <div class="container">
        <!-- <form> -->
          <!-- <div class="text-right">
            <button v-on:click="showLogout" class="btn btn-sm btn-outline-secondary">ログアウト</button>
          </div> -->
          <div class="btn-toolbar pull-right">
            <div class="btn-group">
            <button v-on:click="showLogout" class="btn btn-sm btn-outline-secondary">ログアウト</button>
            </div>
          </div>          
          <div class="form-group form-inline input-group-sm">
              <label class="col-md-2 control-label">配布日年月日</label>
              <input v-model="date_from" type="date" class="form-control col-md-3" id="publication_date_from" name="publication_date_from" placeholder="From">
              <label class="col-md-1 control-label">～</label>
              <input v-model="date_to" type="date" class="form-control col-md-3" id="publication_date_to" name="publication_date_to" placeholder="To">
              <div class="col-md-3"></div>
          </div>
          <div class="btn-toolbar-center">
            <div class="btn-group">
              <button class="btn btn-sm btn-outline-secondary w-150px mr-3" @click="onClickSearch()">検索</button>
            </div>
            <div class="btn-group">
              <button class="btn btn-sm btn-outline-secondary w-150px" @click="onScreenClickDeliveryStatus()">配布状況表示</button>
            </div>
          </div>          
        <!-- </form> -->
        <hr>
      </div>
      <div>検索結果は{{staffs.length}}件です。</div>
        <table class="table table-striped">
          <thead class="table-primary">
            <tr><th>配布日</th><th>案件名</th><th>担当者名</th><th>状態</th><th colspan="1"></th></tr>
          </thead>
          <tbody>
            <!-- <tr v-for="(staff, index) in filteredstaffs" :key="index"> -->
            <tr v-for="(staff, index) in staffs" :key="index">
              <td>{{staff.date}}</td>
              <td class="text-left">{{staff.matter_name}}</td>
              <td class="text-left">{{staff.user_name}}</td>
              <td>{{staff.status}}</td>
              <td>
                <button class="btn btn-outline-primary btn-sm" @click="onClickRireki(staff.id,staff.date)">過去履歴表示</button>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- ログアウト -->
        <Logout></Logout>
        <!-- メッセージ -->
        <MessageDialog></MessageDialog>
      </div>
    </div>
    </div>
</template>

<script>
import { ValidationProvider, extend,localize  } from "vee-validate";
import { required,max,min,email } from 'vee-validate/dist/rules';
import ja from "vee-validate/dist/locale/ja.json";
import axios from 'axios';

import Logout from './Logout.vue'
import MessageDialog from './MessageDialog.vue'
require('dotenv').config();
import gv from '../mixins/globalValiables';


extend('required', required)
extend('max', max)
extend('min', min)
extend('email', email)
localize('ja', ja);
localize({
  ja: {
    names: {
      userid: "ユーザーID",
      password: "パスワード",
    },
   },
});


export default {
  data () {
    return {
      date_from: '',
      date_to: '',
      staffs: [],
      isDisplay: false,

      //ログイン認証情報
      userid:'',
      authtoken: '',
      header: {},


    }
  },

 components: {
    ValidationProvider,
    Logout,
    MessageDialog
  },
  methods: {
    //ログアウト
    showLogout : function() {
        this.$modal.show('dlglogout');
    },
    //検索クリック
    onClickSearch () {
      //スタッフを検索する
      // url = "https://jpsearch.go.jp/api/item/search/jps-cross?keyword=" + this.keyword + "&size=3";
      // axios.get(url).then(response => {this.json = response["data"]["list"]});
    //  this.staffs= [
    //    {
    //      cd: '0001',
    //      date: '2022/03/12',
    //      name: '担当者１',
    //      status: '配布中'
    //    },
    //    {
    //      cd: '0002',
    //      date: '2022/03/12',
    //      name: '担当者２',
    //      status: '配布中'
    //    },
    //    {
    //      cd: '0003',
    //      date: '2022/03/12',
    //      name: '担当者３',
    //      status: '配布中'
    //    },
    //    {
    //      cd: '0004',
    //      date: '2022/03/12',
    //      name: '担当者４',
    //      status: '配布中'
    //    },
    //    {
    //      cd: '0005',
    //      date: '2022/03/12',
    //      name: '担当者５',
    //      status: '配布中'
    //    },
    //    {
    //      cd: '0006',
    //      date: '2022/03/12',
    //      name: '担当者６',
    //      status: '配布中'
    //    },
    //  ]

      //http header設定
      this.header = {}
      this.header['Content-Type'] = 'application/json'
      this.header['authorization'] = this.$store.state.auth.token
      this.header['userid'] = this.$store.state.auth.userid
      console.log('■■■■■■staff■■■■■■■■■■■■');
      console.log(this.header['authorization']);
      console.log(this.header['userid']);
      console.log('date_from:'+this.date_from);
      console.log('date_to:'+this.date_to);
      console.log(`${gv.data().API_URL}/maps/staffsbydate`);
      console.log('■■■■■■■■■■■■■■■■■■');
      const options = {
          method:'post',
          url: `${gv.data().API_URL}/maps/staffsbydate`,
          headers:this.header,
          data: {
              date_from: this.date_from ,
              date_to: this.date_to
          },
          timeout: 4000
      }
      let that = this;
      axios(options)
      .then(response => {
          if (response.status != 200){
              console.log('スタッフの位置ポイントresponseエラー'+response.status);
              // alert(`ログアウト後、再ログインしてください。(001)`);
              // that.$router.push({name: 'Login', params: {}, query: {}});
          }
          var jsonObject = response.data;
          that.staffs = [];
          console.log(jsonObject);
          console.log('スタッフ件数:'+jsonObject.length);

          if (jsonObject.length != 0){
              jsonObject.forEach(function(staffObject, index, array){
                  console.log('client_name:'+staffObject['client_name']);
                  console.log('staffObject:' + staffObject);
                  let staff = {};
                  // key: 'nebmva7eghce42de5cb12ns6v1',
                  // client_name: '税理士法人香陵総合会計事務所',
                  // matter_name: 'テスト案件',
                  // actionday_sd: '2022-04-28',
                  // actionday_ed: '2022-04-28',
                  // distribution_member_id: '10',
                  // distribution_member_name: 'テスト',
                  // distributed: '配布中',
                  // login_user_class: 'クライアント'

                  staff.id=staffObject['distribution_member_id'];
                  staff.date=staffObject['actionday_sd'];
                  staff.matter_name=staffObject['matter_name'];
                  staff.user_class=staffObject['login_user_class'];
                  staff.user_name=staffObject['distribution_member_name'];
                  staff.status = staffObject['distributed'];
                  console.log(staff);
                  that.staffs.push(staff);
              });
          }
          //20221017 ADD
          //イントラからログイン遷移
          console.log('===========================')
          console.log(`this.$route.params.nowstaffcd:${this.$route.params.nowstaffcd}`)
          console.log('===========================')
          if (this.$route.params.nowstaffcd != undefined){
            this.onClickDeliveryStatus();
          }

          //クライアントの場合は地図画面表示
          if (this.$store.state.auth.user_class ==this.gvUserClassClient){
            //20230619
            // this.onClickDeliveryStatus();
            this.isDisplay = true;
          }
          // パートナーの場合地図画面表示
          if (this.$store.state.auth.user_class ==this.gvUserClassPartner){
            //20230619
            // this.onClickDeliveryStatus();
            this.isDisplay = true;
          }
          // 管理者の場合はスタッフ一覧を表示
          if (this.$store.state.auth.user_class ==this.gvUserClassAdmin){
            this.isDisplay = true;
          }

      })
      .catch(err => {
          console.log('================');
          console.log(err);
          console.log('================');
          //TODO alertが出続けるバグ？？？？
          // alert(`ログアウト後、再ログインしてください。(001)`);
          //強制ログアウト（イントラからtokenチェックエラーは、ここでチェック）
          that.gvLogout();
          that.$route.query.token = '';
          //ログイン画面遷移
          that.$store.dispatch(
            'message/create',
            {errorinfo:{error: 'ログインに失敗しました（tokenエラー）',redirect: 'login'} },
            { root: true }
          );
          that.$router.push({name: 'Login', params: {}, query: {}});

      })
      .finally(() => {
      })

    },
    /**
     * 地図配布状況表示クリック
     * @param  
     */
    async onClickDeliveryStatus () {
      //エラーメッセージクリア
      this.$store.dispatch(
        'message/create',
        {errorinfo:{error: '',redirect: ''} },
        { root: true }
      );
      //google map表示の場合はリロードフラグをセット
      if (this.$store.state.auth.googleLayerDisp){
          //reloadMapFlgフラグをoffにして保存
          this.$store.dispatch(
              'auth/setReloadMapFlg',
              {reloadMapFlg:true}
          );
      }
      //20230307 修正
      var japanStandardTimenow = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
      var yyyy = new Date(japanStandardTimenow).getFullYear();
      var mm = new Date(japanStandardTimenow).getMonth()+1;
      var dd = new Date(japanStandardTimenow).getDate();    
      var formattedDate = yyyy + "/" + mm  + "/" + dd;
      console.log('this.gvDebug:'+this.gvDebug);
      if (this.gvDebug){
        formattedDate = "2023/04/02";
      }
      //http header設定
      this.header = {}
      this.header['Content-Type'] = 'application/json'
      this.header['authorization'] = this.$store.state.auth.token
      this.header['userid'] = this.$store.state.auth.userid
      console.log('■■■■■■staff■■■■■■■■■■■■');
      console.log(this.header['authorization']);
      console.log(this.header['userid']);
      console.log('date_from:'+formattedDate);
      console.log('date_to:'+formattedDate);
      console.log(`${gv.data().API_URL}/maps/staffsbydate`);
      console.log('■■■■■■■■■■■■■■■■■■');
      const options = {
          method:'post',
          url: `${gv.data().API_URL}/maps/staffsbydate`,
          headers:this.header,
          data: {
              date_from: formattedDate ,
              date_to: formattedDate
          },
          timeout: 4000
      }
      let that = this;
      axios(options)
      .then(response => {
          if (response.status != 200){
              console.log('スタッフの位置ポイントresponseエラー'+response.status);
          }
          var jsonObject = response.data;
          that.staffs = [];
          console.log(jsonObject);
          console.log('スタッフ件数:'+jsonObject.length);

          if (jsonObject.length != 0){
              jsonObject.forEach(function(staffObject, index, array){
                  console.log('client_name:'+staffObject['client_name']);
                  console.log('staffObject:' + staffObject);
                  let staff = {};
                  staff.id=staffObject['distribution_member_id'];
                  staff.date=staffObject['actionday_sd'];
                  staff.matter_name=staffObject['matter_name'];
                  staff.user_class=staffObject['login_user_class'];
                  staff.user_name=staffObject['distribution_member_name'];
                  staff.status = staffObject['distributed'];
                  console.log(staff);
                  that.staffs.push(staff);
              });
          }
      })
      .catch(err => {
          console.log('================');
          console.log(err);
          console.log('================');
          //TODO alertが出続けるバグ？？？？
          // alert(`ログアウト後、再ログインしてください。(001)`);
          //強制ログアウト（イントラからtokenチェックエラーは、ここでチェック）
          that.gvLogout();
          that.$route.query.token = '';
          //ログイン画面遷移
          that.$store.dispatch(
            'message/create',
            {errorinfo:{error: 'ログインに失敗しました（tokenエラー）',redirect: 'login'} },
            { root: true }
          );
          that.$router.push({name: 'Login', params: {}, query: {}});
      })
      .finally(() => {
      })

     
      //ステートにスタッフリストをセーブして、地図画面へ遷移を行う（※store内で画面遷移させる）
      console.log('that.staffs件数:'+this.staffs.length);
      //スタッフコードのリストを作成
      var paraStaffList = [];
      this.staffs.forEach(function(elem, index) {
        //20230307 スタッフダブり除外（将来的には案件IDが必要と思われる）
        if (paraStaffList.includes(elem.id)==false) {
          paraStaffList.push(elem.id);
        }
      });
      console.log('=====地図配布状況表示クリック stafflist=====');
      console.log(paraStaffList);
      console.log('that.formattedDate'+formattedDate);
      console.log('================================');

      this.$store.dispatch(
          'staff/createlist',
          {
            postingdate: formattedDate,  //システム日時をセット　2022/03/12
            staffcd: '',
            nowstaffcd: this.$route.params.nowstaffcd,  //20221017 ADD
            stafflist: paraStaffList,         //表示可能担当者一覧
            mapmode: this.gvMapModeDelivery,  //配布状況表示モード
          },
      );
      //クライアント、パートナーの場合はスタッフ検索画面は非表示
      //20230619
      this.isDisplay = false;
      // if ((this.$store.state.auth.user_class ==this.gvUserClassClient) || (this.$store.state.auth.user_class ==this.gvUserClassPartner)){
      //   this.isDisplay = false;
      // }else{
      //   this.isDisplay = false;
      // }
    },








    /**
     * 地図配布状況表示クリック
     * @param  
     */
    // async onClickDeliveryStatus () {
    //   //エラーメッセージクリア
    //   this.$store.dispatch(
    //     'message/create',
    //     {errorinfo:{error: '',redirect: ''} },
    //     { root: true }
    //   );
    //   //google map表示の場合はリロードフラグをセット
    //   if (this.$store.state.auth.googleLayerDisp){
    //       //reloadMapFlgフラグをoffにして保存
    //       this.$store.dispatch(
    //           'auth/setReloadMapFlg',
    //           {reloadMapFlg:true}
    //       );
    //   }
    //   //20230307 修正
    //   // var formattedDate = new Date().toISOString().split("T")[0].replaceAll("-", "/");
    //   //var japanStandardTimenow = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
    //   var japanStandardTimenow = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
    //   var yyyy = new Date(japanStandardTimenow).getFullYear();
    //   var mm = new Date(japanStandardTimenow).getMonth()+1;
    //   var dd = new Date(japanStandardTimenow).getDate();    
    //   var formattedDate = yyyy + "/" + mm  + "/" + dd;

    //   // debug あとで削除
    //   if (this.gvDebug){
    //     formattedDate = "2022/04/28";
    //   }
    //   //ステートにスタッフリストをセーブして、地図画面へ遷移を行う（※store内で画面遷移させる）
    //   console.log('that.staffs件数:'+this.staffs.length);
    //   //スタッフコードのリストを作成
    //   var paraStaffList = [];
    //   this.staffs.forEach(function(elem, index) {
    //     //20230307 スタッフダブり除外（将来的には案件IDが必要と思われる）
    //     if (paraStaffList.includes(elem.id)==false) {
    //       paraStaffList.push(elem.id);
    //     }
    //   });
    //   console.log('=====地図配布状況表示クリック stafflist=====');
    //   console.log(paraStaffList);
    //   console.log('that.formattedDate'+formattedDate);
    //   console.log('================================');

    //   this.$store.dispatch(
    //       'staff/createlist',
    //       {
    //         postingdate: formattedDate,  //システム日時をセット　2022/03/12
    //         staffcd: '',
    //         nowstaffcd: this.$route.params.nowstaffcd,  //20221017 ADD
    //         // staffcdlist: ['0001','0002','0003'],
    //         stafflist: paraStaffList,         //表示可能担当者一覧
    //         // stafflist: this.staffs,         //表示可能担当者一覧
    //         mapmode: this.gvMapModeDelivery,  //配布状況表示モード
    //       },
    //   );
    //   //クライアント、パートナーの場合はスタッフ検索画面は非表示
    //   if ((this.$store.state.auth.user_class ==this.gvUserClassClient) || (this.$store.state.auth.user_class ==this.gvUserClassPartner)){
    //     this.isDisplay = false;
    //   }else{
    //     this.isDisplay = true;
    //   }
    //   // const sleep = waitTime => new Promise( resolve => setTimeout(resolve, waitTime) );
    //   // await sleep(3000);
    //   // this.$router.push({name: 'Login', params: {}, query: {}});
    // },




    // /**
    //  * 地図過去履歴表示クリック
    //  * 指定スタッフ単位で一括履歴表示を行う。
    //  * @param  
    //  *  cd    :スタッフCD
    //  *  date  :配布日
    //  */
    // onClickRireki (cd,date) {
    //   //配布一覧取得
    //   console.log('===========================');
    //   console.log('staffcd:'+ cd);
    //   console.log('===========================');
    //   // this.$store.dispatch(
    //   //     'staff/create',
    //   //     {
    //   //       postingdate: date,
    //   //       staffcd: cd,
    //   //     }
    //   // )

    //   //エラーメッセージクリア
    //   this.$store.dispatch(
    //     'message/create',
    //     {errorinfo:{error: '',redirect: ''} },
    //     { root: true }
    //   );
    //   //google map表示の場合はリロードフラグをセット
    //   if (this.$store.state.auth.googleLayerDisp){
    //       //reloadMapFlgフラグをoffにして保存
    //       this.$store.dispatch(
    //           'auth/setReloadMapFlg',
    //           {reloadMapFlg:true}
    //       );
    //   }
    //   //ステートに選択スタッフをセーブして、地図画面へ遷移を行う（※store内で画面遷移させる）
    //   //スタッフコードのリストを作成
    //   var paraStaffList = [];
    //   this.staffs.forEach(function(elem, index) {
    //     //20230307 スタッフダブり除外（将来的には案件IDが必要と思われる）
    //     if (paraStaffList.includes(elem.id)==false) {
    //       paraStaffList.push(elem.id);
    //     }
    //   });
    //   console.log('=====地図過去履歴表示クリック stafflist=====');
    //   console.log('staffcd:'+ cd);
    //   console.log('paraStaffList:'+ paraStaffList);
    //   console.log(paraStaffList);
    //   console.log('===========================');
    //   this.$store.dispatch(
    //       // 'staff/create',
    //       'staff/createlist',
    //       {
    //         postingdate: date,
    //         staffcd: cd,
    //         // staffcdlist: [cd],
    //         // stafflist: this.staffs,         //表示可能担当者一覧
    //         stafflist: paraStaffList,         //表示可能担当者一覧
    //         mapmode: this.gvMapModeRireki,  //履歴表示モード
    //       },
    //   );
    // },
    /**
     * 地図配布状況表示クリック（画面からクリック）
     * @param  
     */
    async onScreenClickDeliveryStatus () {
      //エラーメッセージクリア
      this.$store.dispatch(
        'message/create',
        {errorinfo:{error: '',redirect: ''} },
        { root: true }
      );
      //google map表示の場合はリロードフラグをセット
      if (this.$store.state.auth.googleLayerDisp){
          //reloadMapFlgフラグをoffにして保存
          this.$store.dispatch(
              'auth/setReloadMapFlg',
              {reloadMapFlg:true}
          );
      }
      //現在日でスタッフ一覧を取得
      //20230307 修正
      //var formattedDate = new Date().toISOString().split("T")[0].replaceAll("-", "/");
      //var japanStandardTimenow = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
      var japanStandardTimenow = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
      var yyyy = new Date(japanStandardTimenow).getFullYear();
      var mm = new Date(japanStandardTimenow).getMonth()+1;
      var dd = new Date(japanStandardTimenow).getDate();    
      var formattedDate = yyyy + "/" + mm  + "/" + dd;
      if (this.gvDebug){
        formattedDate = "2023/04/02";
      }
      //http header設定
      this.header = {}
      this.header['Content-Type'] = 'application/json'
      this.header['authorization'] = this.$store.state.auth.token
      this.header['userid'] = this.$store.state.auth.userid
      console.log('■■■■■■staff■■■■■■■■■■■■');
      console.log(this.header['authorization']);
      console.log(this.header['userid']);
      console.log('date_from:'+formattedDate);
      console.log('date_to:'+formattedDate);
      console.log(`${gv.data().API_URL}/maps/staffsbydate`);
      console.log('■■■■■■■■■■■■■■■■■■');
      const options = {
          method:'post',
          url: `${gv.data().API_URL}/maps/staffsbydate`,
          headers:this.header,
          data: {
              date_from: formattedDate ,
              date_to: formattedDate
          },
          timeout: 4000
      }
      let that = this;
      axios(options)
      .then(response => {
          if (response.status != 200){
              console.log('スタッフの位置ポイントresponseエラー'+response.status);
          }
          var jsonObject = response.data;
          that.staffs = [];
          console.log(jsonObject);
          console.log('スタッフ件数:'+jsonObject.length);

          if (jsonObject.length != 0){
              jsonObject.forEach(function(staffObject, index, array){
                  console.log('client_name:'+staffObject['client_name']);
                  console.log('staffObject:' + staffObject);
                  let staff = {};
                  staff.id=staffObject['distribution_member_id'];
                  staff.date=staffObject['actionday_sd'];
                  staff.matter_name=staffObject['matter_name'];
                  staff.user_class=staffObject['login_user_class'];
                  staff.user_name=staffObject['distribution_member_name'];
                  staff.status = staffObject['distributed'];
                  console.log(staff);
                  that.staffs.push(staff);
              });
          }
      })
      .catch(err => {
          console.log('================');
          console.log(err);
          console.log('================');
          //TODO alertが出続けるバグ？？？？
          // alert(`ログアウト後、再ログインしてください。(001)`);
          //強制ログアウト（イントラからtokenチェックエラーは、ここでチェック）
          that.gvLogout();
          that.$route.query.token = '';
          //ログイン画面遷移
          that.$store.dispatch(
            'message/create',
            {errorinfo:{error: 'ログインに失敗しました（tokenエラー）',redirect: 'login'} },
            { root: true }
          );
          that.$router.push({name: 'Login', params: {}, query: {}});
      })
      .finally(() => {
      })

      //ステートにスタッフリストをセーブして、地図画面へ遷移を行う（※store内で画面遷移させる）
      console.log('that.staffs件数:'+this.staffs.length);
      //スタッフコードのリストを作成
      var paraStaffList = [];
      this.staffs.forEach(function(elem, index) {
        //20230307 スタッフダブり除外（将来的には案件IDが必要と思われる）
        if (paraStaffList.includes(elem.id)==false) {
          paraStaffList.push(elem.id);
        }
      });
      console.log('=======地図配布状況表示クリック（画面からクリック）stafflist=====');
      console.log(paraStaffList);
      console.log('that.formattedDate'+formattedDate);
      console.log('================================');

      this.$store.dispatch(
          'staff/createlist',
          {
            postingdate: formattedDate,  //システム日時をセット　2022/03/12
            staffcd: '',
            nowstaffcd: this.$route.params.nowstaffcd,  //20221017 ADD
            stafflist: paraStaffList,         //表示可能担当者一覧
            mapmode: this.gvMapModeDelivery,  //配布状況表示モード
          },
      );
      //クライアント、パートナーの場合はスタッフ検索画面は非表示
      //20230619
      this.isDisplay = true;
      // if ((this.$store.state.auth.user_class ==this.gvUserClassClient) || (this.$store.state.auth.user_class ==this.gvUserClassPartner)){
      //   this.isDisplay = false;
      // }else{
      //   this.isDisplay = true;
      // }
    },
    /**
     * 地図過去履歴表示クリック
     * 指定スタッフ単位で一括履歴表示を行う。
     * @param  
     *  cd    :スタッフCD
     *  date  :配布日
     */
    onClickRireki (cd,date) {
      //配布一覧取得
      console.log('===========================');
      console.log('staffcd:'+ cd);
      console.log('===========================');
      // this.$store.dispatch(
      //     'staff/create',
      //     {
      //       postingdate: date,
      //       staffcd: cd,
      //     }
      // )

      //エラーメッセージクリア
      this.$store.dispatch(
        'message/create',
        {errorinfo:{error: '',redirect: ''} },
        { root: true }
      );
      //google map表示の場合はリロードフラグをセット
      if (this.$store.state.auth.googleLayerDisp){
          //reloadMapFlgフラグをoffにして保存
          this.$store.dispatch(
              'auth/setReloadMapFlg',
              {reloadMapFlg:true}
          );
      }
      //ステートに選択スタッフをセーブして、地図画面へ遷移を行う（※store内で画面遷移させる）
      //スタッフコードのリストを作成
      var paraStaffList = [];
      this.staffs.forEach(function(elem, index) {
        //20230307 スタッフダブり除外（将来的には案件IDが必要と思われる）
        if (paraStaffList.includes(elem.id)==false) {
          paraStaffList.push(elem.id);
        }
      });
      console.log('=====地図過去履歴表示クリック stafflist=====');
      console.log('staffcd:'+ cd);
      console.log('paraStaffList:'+ paraStaffList);
      console.log(paraStaffList);
      console.log('===========================');
      this.$store.dispatch(
          // 'staff/create',
          'staff/createlist',
          {
            postingdate: date,
            staffcd: cd,
            // staffcdlist: [cd],
            // stafflist: this.staffs,         //表示可能担当者一覧
            stafflist: paraStaffList,         //表示可能担当者一覧
            mapmode: this.gvMapModeRireki,  //履歴表示モード
          },
      );
    }
    
  },
  computed: {
    // filteredstaffs () {
    //  let staffs = []
    //  // this.usersはデータ内のusers, 5個あるので5回繰り返す
    //  for(let i = 0; i < this.staffs.length; i++) {
    //    let staff = this.staffs[i];
    //   //条件絞り込み
    //   //  if(staffs.name.indexOf(this.keyword) !== -1 || staffs.email.indexOf(this.keyword) !== -1) {
    //   //    staffs.push(staff);
    //   //  }
    //    staffs.push(staff);
    //  }
    //  return staffs;
    // },    
    isValidated () {
        //オブジェクトが持つプロパティの 名前の配列
        return Object.keys(this.fields).every(k => this.fields[k].validated) &&
        Object.keys(this.fields).every(k => this.fields[k].valid)
    },
    //ストアのtoken監視用
    token () {
      return this.$store.state.auth.token
    },
    //ストアのerror監視用
    errorMessage () {
      return this.$store.state.message.error
    }
  },
  created: function () {
    //検索条件初期化
    // this.date_from = "2022-04-23";
    // this.date_to = "2022-04-24";
    //var now = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
    var now = new Date().toLocaleString({ timeZone: 'Asia/Tokyo' });
    var yyyy = new Date(now).getFullYear();
    var mm = new Date(now).getMonth()+1;
    var dd = new Date(now).getDate();    
    console.log('日付:'+yyyy+'-'+mm+'-'+dd);
    this.date_from = yyyy+'-'+String(mm).padStart(2, '0')+'-'+String(dd).padStart(2, '0');
    this.date_to = yyyy+'-'+String(mm).padStart(2, '0')+'-'+String(dd).padStart(2, '0');
    if (this.gvDebug){
      this.date_from = "2023-04-02";
      this.date_to = "2023-04-02";
    }


    //初期検索実行
    this.onClickSearch();

    this.$store.dispatch('message/destroy')
    // 既に認証済みの場合
    if (this.$store.state.auth.token) {
      console.log('login auth.token:'+this.$store.state.auth.token);
      console.log('login auth.userid:'+this.$store.state.auth.userid);
      console.log('login auth.user_class:'+this.$store.state.auth.user_class);
      console.log('login auth.company_id:'+this.$store.state.auth.company_id);
      // this.$router.push({name: 'AppMap', params: {}, query: {}});

    }else{
      console.log('login created no token!!');
      this.$router.push({name: 'Login', params: {}, query: {}});
    }
  },
  watch: {
    // ストアのトークンを監視してトークンに値が入ったら認証済みとする
    // token (newToken) {
    token (newValue, oldValue){
      console.log('login watch newValue:'+newValue);
      console.log('login watch oldValue:'+oldValue);
      console.log('login watch auth.token:'+this.$store.state.auth.token);
      console.log('login watch auth.userid:'+this.$store.state.auth.userid);
      console.log('login watch auth.user_class:'+this.$store.state.auth.user_class);
      console.log('login watch auth.company_id:'+this.$store.state.auth.company_id);
      //トークンクリアで強制ログアウト
      if ((newValue =='') || (typeof newValue === "undefined") ){
          this.$router.push({name: 'Login', params: {}, query: {}});
      }else{
        this.$router.push({name:'AppMap', params: {reloadflg: true}});
      }
    },
    // ストアのerrorを監視してerrorに値が入ったらエラーメッセージダイアログ表示する
    errorMessage (newValue, oldValue){
      console.log('error watch newValue:'+newValue);
      console.log('error watch oldValue:'+oldValue);
      console.log('error watch auth.token:'+this.$store.state.message.error);
      // エラーメッセージ表示
      if (newValue !=''){
          this.$modal.show('MessageDialog');
      }
    },
  }
}
</script>



<style>
.w-25 {
  width: 25% !important;
}
.w-150px {
  width: 150px !important;
}

</style>