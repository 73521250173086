import Vue from 'vue'
import Vuex from 'vuex'
// import VeeValidate, { Validator } from 'vee-validate'
// import VeeValidate from 'vee-validate';
import { ValidationProvider, extend,localize  } from "vee-validate";
import { required,max,min,email } from 'vee-validate/dist/rules';
import ja from "vee-validate/dist/locale/ja.json";
import BootstrapVue from "bootstrap-vue"
import App from './App.vue'
import Login from './components/Login.vue'

// import AppMobile from './AppMobile.vue'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.css"
import 'ol/ol.css';

import router from './route.js'
import store from './store/index.js'


import gv from './mixins/globalValiables.js'
import isMobile from 'ismobilejs'
/////////////////////////////import "./assets/css/drawer.scss";
import VModal from 'vue-js-modal'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
// require("babel-core/register");
// require("babel-polyfill");
Vue.mixin(gv)    // mixinに登録

Vue.use(Vuex)
Vue.use(BootstrapVue)
Vue.use(VModal);

extend('required', required)
extend('max', max)
extend('min', min)
extend('email', email)
localize('ja', ja);
Vue.component('validation-provider', ValidationProvider);
Vue.config.productionTip = false

const userAgent = navigator.userAgent;
console.log(isMobile(userAgent).phone);

Vue.prototype.$isMobileAny = isMobile(userAgent).phone;
// Vue.prototype.$isMobileAny = true;
if(Vue.prototype.$isMobileAny) {
    // スマホ or タブレットの処理
    // new Vue({
    //   router,
    //   el: '#app',
    //   render: h => h(AppMobile),
    //   data: {
    //     saveLayers:[] //選択済みレイヤー
    //   },
    //   created: function() {
    //     console.log('スマホ:'+Vue.prototype.$isMobileAny);
    //   }      
    // })
} else {
    // スマホ、タブレット以外の処理
    // new Vue({
    //   router,
    //   store,
    //   el: '#app',
    //   render: h => h(App),
    //   //render: h => h(Login),
    //   created: function() {
    //     console.log('PC:'+Vue.prototype.$isMobileAny);
    //   }      
    // })
    new Vue({
      router,
      store,
      render: h => h(App),
      //render: h => h(Login),
      // watch:{
      //   '$route' (to) {
      //      if(to.currentRoute.meta.reload==true){window.location.reload()}
      // }},      
    created: function() {
        console.log('PC:'+Vue.prototype.$isMobileAny);
      }      
    }).$mount('#app')
  }    
