import gv from './mixins/globalValiables';
import { asArray } from 'ol/color';
import {WKT} from 'ol/format';

export const titleSAIGAI='災害地点';
export const titleMOKUHYOU='出動目標';
export const titleTEL='発信位置';
export const titleKINSHI='車両';
export const titleATTR='属性';
//災害地点ポップアップ
export var contentSAIGAI= `
<style>
.popover{
    max-width: 400px;
}
</style>
<div class="row">
  <div class="col-sm-5">整番</div><div class="col-sm-7">#refNo#</div>
  <div class="col-sm-5">事案処理状態</div><div class="col-sm-7">#casePrcsStat#</div>
  <div class="col-sm-5">災害区分</div><div class="col-sm-7">#disasterClsNo#</div>
  <div class="col-sm-5">住所</div><div class="col-sm-7">#disasterPointAddr#</div>
  <div class="col-sm-5">名称</div><div class="col-sm-7">#disasterPointName#</div>
  <div class="col-sm-12"><img style="width:30vw;" src="#url#" alt=""></div>
</div>                
`;

//出動目標ポップアップ
export var contentMOKUHYOU= `
<style>
.popover{
    max-width: 400px;
}
</style>
<div class="row">
  <div class="col-sm-5">整番</div><div class="col-sm-7">#refNo#</div>
  <div class="col-sm-5">住所</div><div class="col-sm-7">#targetAddr#</div>
  <div class="col-sm-5">名称</div><div class="col-sm-7">#targetName#</div>
  <div class="col-sm-12"><img style="width:30vw;" src="#url#" alt=""></div>
</div>                
`;

//発信位置ポップアップ
{/* <div class="col-sm-5">住所</div><div class="col-sm-7">notifierAddr</div> */}

export var contentTEL= `
<style>
.popover{
    max-width: 400px;
}
</style>
<div class="row">
  <div class="col-sm-5">届出番号</div><div class="col-sm-7">#notifyNo#</div>
  <div class="col-sm-5">受付日時</div><div class="col-sm-7">#receptionTime#</div>
  <div class="col-sm-5">整番</div><div class="col-sm-7">#refNo#</div>
  <div class="col-sm-5">住所</div><div class="col-sm-7">#notifierAddr#</div>
  <div class="col-sm-5">氏名</div><div class="col-sm-7">#notifierName#</div>
  <div class="col-sm-5">連絡先</div><div class="col-sm-7">#telNo#</div>
  <div class="col-sm-5">種別番号</div><div class="col-sm-7">#notifyTypeNo#</div>
  <div class="col-sm-5">内容</div><div class="col-sm-7">#notifyContent#</div>
  <div class="col-sm-5">通信事業者</div><div class="col-sm-7">#carrierTypeNo#</div>
  <div class="col-sm-5">誤差半径</div><div class="col-sm-7">#errorRadius#</div>
  <div class="col-sm-5">指令台番号</div><div class="col-sm-7">#commandDskNo#</div>
  <div class="col-sm-12"><img style="width:30vw;" src="#url#" alt=""></div>
</div>                
`;

//車両ポップアップ
export var contentKINSHI= `
<style>
.popover{
    max-width: 400px;
}
</style>
<div class="row">
  <div class="col-sm-5">車両番号</div><div class="col-sm-7">#refNo#</div>
  <div class="col-sm-5">名称</div><div class="col-sm-7">#name#</div>
  <div class="col-sm-5">動態</div><div class="col-sm-7">#doutai#</div>
  <div class="col-sm-5">住所</div><div class="col-sm-7">#address#</div>
  <div class="col-sm-12"><img style="width:30vw;" src="#url#" alt=""></div>
</div>                
`;

//属性ポップアップ
export var contentATTR= `
<style>
.popover{
    max-width: 400px;
}
</style>
<div class="row">
  <div class="col-sm-5">属性１</div><div class="col-sm-7">#attr1#</div>
  <div class="col-sm-12"><img style="width:30vw;" src="#url#" alt=""></div>
</div>                
`;


export function setTitle(feature){
    let title_name='';
    if (feature.getProperties().syubetsu=='saigai'){
        title_name = titleSAIGAI;
    }
    if (feature.getProperties().syubetsu=='mokuhyou'){
        title_name=titleMOKUHYOU;
    }
    if (feature.getProperties().syubetsu=='tel'){
        title_name=titleTEL;
    }
    if (feature.getProperties().syubetsu=='kinshi'){
        title_name=titleKINSHI;
    }
    if (feature.getProperties().syubetsu=='attr'){
        title_name=titleATTR;
    }
    return title_name;
}
export function setDB2Template(feature){
    let contents='';
    if (feature.getProperties().syubetsu=='saigai'){
        contents = contentSAIGAI;
        contents = contents.replace('#refNo#', feature.getProperties().refNo);
        if (feature.getProperties().casePrcsStat == 0){
            contents = contents.replace('#casePrcsStat#', 'その他');
        }else if (feature.getProperties().casePrcsStat == 1){
            contents = contents.replace('#casePrcsStat#', '支援終了');
        }else if (feature.getProperties().casePrcsStat == 2){
            contents = contents.replace('#casePrcsStat#', '災害終了');
        }
        if (feature.getProperties().disasterClsNo == 0){
            contents = contents.replace('#disasterClsNo#', '火災');
        }else if (feature.getProperties().disasterClsNo == 1){
            contents = contents.replace('#disasterClsNo#', '救急');
        }else if (feature.getProperties().disasterClsNo == 2){
            contents = contents.replace('#disasterClsNo#', '警戒');
        }else if (feature.getProperties().disasterClsNo == 3){
            contents = contents.replace('#disasterClsNo#', '救助');
        }else if (feature.getProperties().disasterClsNo == 4){
            contents = contents.replace('#disasterClsNo#', '調査');
        }else if (feature.getProperties().disasterClsNo == 5){
            contents = contents.replace('#disasterClsNo#', 'その他');
        }
        contents = contents.replace('#disasterPointAddr#', feature.getProperties().disasterPointAddr);
        contents = contents.replace('#disasterPointName#', feature.getProperties().disasterPointName);
        if (feature.getProperties().url != ""){
            contents = contents.replace('#url#', feature.getProperties().url);
        }
}
    if (feature.getProperties().syubetsu=='mokuhyou'){
            contents = contentMOKUHYOU;
            contents = contents.replace('#refNo#', feature.getProperties().refNo);
            contents = contents.replace('#targetAddr#', feature.getProperties().targetAddr);
            contents = contents.replace('#targetName#', feature.getProperties().targetName);
            if (feature.getProperties().url != ""){
                contents = contents.replace('#url#', feature.getProperties().url);
            }
    }
    if (feature.getProperties().syubetsu=='tel'){
            console.log(feature.getProperties().receptionTime);
            contents = contentTEL;
            contents = contents.replace('#notifyNo#', feature.getProperties().notifyNo);
            contents = contents.replace('#receptionTime#', feature.getProperties().receptionTime);
            contents = contents.replace('#refNo#', feature.getProperties().refNo);
            contents = contents.replace('#notifierAddr#', feature.getProperties().notifierAddr);
            contents = contents.replace('#notifierName#', feature.getProperties().notifierName);
            contents = contents.replace('#telNo#', feature.getProperties().telNo1+feature.getProperties().telNo2+feature.getProperties().telNo3);
            contents = contents.replace('#notifyTypeNo#', feature.getProperties().notifyTypeNo);
            contents = contents.replace('#notifyContent#', feature.getProperties().notifyContent);
            if (feature.getProperties().carrierTypeNo == 1){
                contents = contents.replace('#carrierTypeNo#', '固定電話');
            }else if (feature.getProperties().carrierTypeNo == 2){
                contents = contents.replace('#carrierTypeNo#', '携帯電話');
            }else if (feature.getProperties().carrierTypeNo == 3){
                contents = contents.replace('#carrierTypeNo#', 'HELPNET');
            }
            contents = contents.replace('#errorRadius#', feature.getProperties().errorRadius);
            contents = contents.replace('#commandDskNo#', feature.getProperties().commandDskNo);
            if (feature.getProperties().url != ""){
                contents = contents.replace('#url#', feature.getProperties().url);
            }
    }
    if (feature.getProperties().syubetsu=='kinshi'){
            contents = contentKINSHI;
            contents = contents.replace('#refNo#', feature.getProperties().refNo);
            // contents = contents.replace('#name#', feature.getProperties().name);
            if (feature.getProperties().name == "hashigo"){
                contents = contents.replace('#name#', '梯子');
            }else if (feature.getProperties().name == "kyujyo"){
                contents = contents.replace('#name#', '救助');
            }else if (feature.getProperties().name == "ponpu"){
                contents = contents.replace('#name#', 'ポンプ');
            }else if (feature.getProperties().name == "tanku"){
                contents = contents.replace('#name#', 'タンク');
            }
            contents = contents.replace('#doutai#', feature.getProperties().doutai);
            contents = contents.replace('#address#', feature.getProperties().address);
            if (feature.getProperties().url != ""){
                contents = contents.replace('#url#', feature.getProperties().url);
            }
    }
    if (feature.getProperties().syubetsu=='attr'){
            contents = contentATTR;
            contents = contents.replace('#attr1#', feature.getProperties().refNo);
            if (feature.getProperties().url != ""){
                contents = contents.replace('#url#', feature.getProperties().url);
            }
    }
    return contents;
}


//-------------------------------------------
//災害データ一覧を取得
//-------------------------------------------
export function getSaigaiList(map){
    let params = {	
        // limit: 10	
    };
    map.ecomApi.getFeatureList('DisasterPoint', params, function(data) {
        console.log('DisasterPoint getFeatureList start');
        // console.log(JSON.stringify(data));
        // {"total":1,"results":[{"deletable":false,"editable":false,"fid":1,"layerId":"c1","properties":{"refNo":"1","disasterClsNo":"0","casePrcsStat":"0","disasterPointName":"災害地点名称テスト","disasterPointAddr":"災害地点住所テスト"},"wkt":"POINT(136.914187726069 35.1608079713105)"}]}
        let wkt = new WKT;
        for(let i in data.results) {
            var rec={ type:'point',fid:1, lon: 137.0020882324639, lat: 35.200328003372306, syubetsu: "saigai" ,refNo:123123123,casePrcsStat:1,disasterClsNo:1,disasterPointAddr:'杉並区1-4-3',disasterPointName:'田中'}
            rec.type = "point";
            rec.syubetsu = "saigai";
            rec.fid = parseInt(data.results[i].fid); 
            rec.refNo = parseInt(data.results[i].properties.refNo); 
            rec.casePrcsStat = parseInt(data.results[i].properties.casePrcsStat);
            rec.disasterClsNo = parseInt(data.results[i].properties.disasterClsNo);
            rec.disasterPointAddr = data.results[i].properties.disasterPointAddr; 
            rec.disasterPointName = data.results[i].properties.disasterPointName;
            var geom = wkt.readGeometry(data.results[i].wkt);
            rec.lon = geom.flatCoordinates[0];
            rec.lat = geom.flatCoordinates[1];
            console.log('getFeatureList rec.lon'+rec.lon+ ' rec.lat'+rec.lat);
        
            map.coordinates_saigai.push(rec);
        }               
        map.addPlotPoints("saigai",map.coordinates_saigai);
        //災害の取得描画が完了したら目標物を取得し描画を行う
        getMokuhyouList(map);
        console.log('DisasterPoint getFeatureList end');
    }.bind(map));

    // return coordinates_saigai;
}
export function getMokuhyouList(map){
    let params = {	
        // limit: 10	
    };
    map.ecomApi.getFeatureList('DispatchTarget', params, function(data) {
        console.log('DispatchTarget getFeatureList start');
        // console.log(JSON.stringify(data));
        // {"total":1,"results":[{"deletable":false,"editable":false,"fid":1,"layerId":"c1","properties":{"refNo":"1","disasterClsNo":"0","casePrcsStat":"0","disasterPointName":"災害地点名称テスト","disasterPointAddr":"災害地点住所テスト"},"wkt":"POINT(136.914187726069 35.1608079713105)"}]}
        let wkt = new WKT;
        for(let i in data.results) {
            var rec={ type:'point',fid:1, lon: 137.0020882324639, lat: 35.210328003372306, syubetsu: "mokuhyou" ,refNo:123123123,targetAddr:'杉並区3-6-7',targetName:'コンビニ',url:''};
            rec.type = "point";
            rec.syubetsu = "mokuhyou";
            rec.fid = parseInt(data.results[i].fid); 
            rec.refNo = parseInt(data.results[i].properties.refNo); 
            rec.targetAddr = data.results[i].properties.targetAddr; 
            rec.targetName = data.results[i].properties.targetName;
            var geom = wkt.readGeometry(data.results[i].wkt);
            rec.lon = geom.flatCoordinates[0];
            rec.lat = geom.flatCoordinates[1];
            //取り敢えず画像は１個固定とする
            rec.url = ''
            if (data.results[i].files){
                rec.url = gv.data().serverUrl.slice( 0, -1 ) + data.results[i].files[0].url; 
                console.log(rec.url);
            }
            map.coordinates_mokuhyou.push(rec);
        }               
        map.addPlotPoints("mokuhyou",map.coordinates_mokuhyou);
        console.log('DispatchTarget getFeatureList end');
    }.bind(map));
    // return coordinates_mokuhyou;
    
}
export function getTelList(map){
    let params = {	
        // limit: 10	
    };
    map.ecomApi.getFeatureList('Notification', params, function(data) {
        console.log('Notification getFeatureList start');
        // console.log(JSON.stringify(data));
        // [{"deletable":false,"editable":false,"fid":1,"layerId":"c3","properties":{"notifyContent":"届出内容テスト","telNo2":"2222","telNo1":"111","notifyTypeNo":"1","telNo3":"3333","refNo":"1","receptionTime":"2020/11/20 09:00:00","commandDskNo":"1","notifierName":"山田 太郎","errorRadius":"1.0","notifierAddr":"届出者住所テスト","carrierTypeNo":"1","notifyNo":"1"},"wkt":"POINT(136.913876575452 35.1594703576666)"}]}
        let wkt = new WKT;
        for(let i in data.results) {
            var rec={ type:'point',fid:1, lon: 137.0020882324639, lat: 35.220328003372306, syubetsu: "tel" ,notifyNo:1,receptionTime:'2020/1/2',refNo:123123123,notifierAddr:'武蔵野市1-3-4',notifierName:'山田',telNo1:'03',telNo2:'03',telNo3:'03',notifyTypeNo:1,notifyContent:'',carrierTypeNo:1,errorRadius:'',commandDskNo:12345}
            rec.type = "point";
            rec.syubetsu = "tel";
            rec.fid = parseInt(data.results[i].fid); 
            rec.notifyNo = parseInt(data.results[i].properties.notifyNo); 
            rec.receptionTime = data.results[i].properties.receptionTime; 
            rec.refNo = parseInt(data.results[i].properties.refNo); 
            rec.notifierAddr = data.results[i].properties.notifierAddr; 
            rec.notifierName = data.results[i].properties.notifierName;
            rec.telNo1 = data.results[i].properties.telNo1;
            rec.telNo2 = data.results[i].properties.telNo2; 
            rec.telNo3 = data.results[i].properties.telNo3;
            rec.notifyTypeNo = parseInt(data.results[i].properties.notifyTypeNo);
            rec.notifyContent = data.results[i].properties.notifyContent;
            rec.carrierTypeNo = parseInt(data.results[i].properties.carrierTypeNo);
            rec.errorRadius = parseInt(data.results[i].properties.errorRadius);
            rec.commandDskNo = parseInt(data.results[i].properties.commandDskNo);
            var geom = wkt.readGeometry(data.results[i].wkt);
            rec.lon = geom.flatCoordinates[0];
            rec.lat = geom.flatCoordinates[1];
        
            map.coordinates_tel.push(rec);
        }               
        map.addPlotPoints("tel",map.coordinates_tel);
        console.log('Notification getFeatureList end');
    }.bind(map));
    // return coordinates_tel;
    
}
export function getAttrList(map){
    let params = {	
        // limit: 10	
    };
    map.ecomApi.getFeatureList('AttrPoint', params, function(data) {
        console.log('AttrPoint getFeatureList start');
        // console.log(JSON.stringify(data));
        let wkt = new WKT;
        for(let i in data.results) {
            var rec={ type:'point',fid:1, lon: 137.0020882324639, lat: 35.200328003372306, syubetsu: "attr" ,refNo:123123123,url:''}
            rec.type = "point";
            rec.syubetsu = "attr";
            rec.fid = parseInt(data.results[i].fid); 
            rec.refNo = parseInt(data.results[i].properties.refNo); 
            var geom = wkt.readGeometry(data.results[i].wkt);
            rec.lon = geom.flatCoordinates[0];
            rec.lat = geom.flatCoordinates[1];
        
            map.coordinates_attr.push(rec);
        }               
        map.addPlotPoints("attr",map.coordinates_attr);
        console.log('AttrPoint getFeatureList end');
    }.bind(map));
    // return coordinates_attr;
    
}
// 災害
// jian																				

// refNo						整番														
// jianPrcsStat						事案処理状態					"1：支援終了2：災害終了3：その他"									
// saigaiKbnNo						災害区分番号					"0：火災1：救急2：警戒3：救助4：調査5：その他"									
// saigaiPntAddr						災害地点住所														
// saigaiPntName						災害地点名称														


// 目標
// mokuhyou				

// refNo					整番					数値(整数)				
// mokuhyouAddr					目標物住所					文字列				
// mokuhyouName					目標物名称					文字列				


// 発信者
// notification				

// notifyNo					届出番号					数値(整数)				
// receptionTime					受付日時					日付と時間				
// notifierName					届出者氏名					文字列				
// telNo1					連絡先（市外局番）					文字列				
// telNo2					連絡先（局番）					文字列				
// telNo3					連絡先（番号）					文字列				
// notifyTypeNo					届出種別番号					数値(整数)				"1：固定電話（黒電話、IP電話）2：携帯電話3：HELPNET"									
// notifyContent					届出内容					文字列				
// carrierTypeNo					通信事業者種別番号					数値(整数)				
// terminalNo					端末番号					数値(整数)				

// 地図設定
// MapSetting				


// map					地図					文字列				
// lon					中心位置経度					数値				
// lat					中心位置緯度					数値				
// zoomLevel					縮尺					数値				
// iconZoomLevel					小アイコン切り替え縮尺					数値				
// circleDistance					同心円間隔					数値				
// circleCnt					同心円本数					数値(整数)				
// circleLineColor					同心円の線色					文字列				
// circleLineWidth					同心円の線太さ					数値				
// circleLineDash					同心円の線種類					文字列				
// arrowLineColor					矢印の線色					文字列				
// arrowLineWidth					矢印の線太さ					数値				
// arrowFillColor					矢印の塗りつぶし色					文字列				
// arrowLineDash					矢印の線種類					文字列				







// //災害地点ポップアップ
// export var contentSAIGAI= 
// '<div  id="popover-content" style="width: 800px; max-width: 100%;">'+
// '<div id="colom_top">'+
//     '<p>'+'整番'+'</p>'+
//     '<p>'+'事案処理状態'+'</p>'+
//     '<p>'+'災害区分番号'+'</p>'+
//     '<p>'+'住所'+'</p>'+
//     '<p>'+'名称'+'</p>'+
// '</div>'+
// '<div id="colom_end" style="display:none;">'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '</div>'+
// '</div>'+
// '<p>'+
// '  <a href="#" id="link_view2" onClick="toggle_view2();return false;">(more)</a>'+
// '  <a href="#" id="link_hidden2" onClick="toggle_hidden2();return false;" style="display:none;">(閉じる)</a>'+
// '</p>'+
// '<script language="JavaScript" type="text/javascript">'+
// '<!--'+
// 'var elem2_1 = document.getElementById("colom_end");'+
// 'var elem2_2 = document.getElementById("link_view2");'+
// 'var elem2_3 = document.getElementById("link_hidden2");'+
// 'function toggle_view2() {'+
// '  elem2_1.style.display = "";'+
// '  elem2_2.style.display = "none";'+
// '  elem2_3.style.display = "";'+
// '}'+
// 'function toggle_hidden2() {'+
// '  elem2_1.style.display = "none";'+
// '  elem2_2.style.display = "";'+
// '  elem2_3.style.display = "none";'+
// '}'+
// '-->'+
// '<' + '/script>'

// //出動目標ポップアップ
// export var contentMOKUHYOU= 
// '<div id="colom_top">'+
// '<p>'+'整番'+'</p>'+
// '<p>'+'住所'+'</p>'+
// '<p>'+'名称'+'</p>'+
// '</div>'+
// '<div id="colom_end" style="display:none;">'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '</div>'+
// '<p>'+
// '  <a href="#" id="link_view2" onClick="toggle_view2();return false;">(more)</a>'+
// '  <a href="#" id="link_hidden2" onClick="toggle_hidden2();return false;" style="display:none;">(閉じる)</a>'+
// '</p>'+
// '<script language="JavaScript" type="text/javascript">'+
// '<!--'+
// 'var elem2_1 = document.getElementById("colom_end");'+
// 'var elem2_2 = document.getElementById("link_view2");'+
// 'var elem2_3 = document.getElementById("link_hidden2");'+
// 'function toggle_view2() {'+
// '  elem2_1.style.display = "";'+
// '  elem2_2.style.display = "none";'+
// '  elem2_3.style.display = "";'+
// '}'+
// 'function toggle_hidden2() {'+
// '  elem2_1.style.display = "none";'+
// '  elem2_2.style.display = "";'+
// '  elem2_3.style.display = "none";'+
// '}'+
// '-->'+
// '<' + '/script>'

// //発信位置ポップアップ
// export var contentTEL= 
// '<div id="colom_top">'+
// '<p>'+'届出番号'+'</p>'+				
// '<p>'+'受付日時	'+'</p>'+			
// '<p>'+'整番'+'</p>'+				
// '<p>'+'届出者住所'+'</p>'+				
// '<p>'+'届出者氏名'+'</p>'+				
// '<p>'+'連絡先'+'</p>'+				
// '</div>'+
// '<div id="colom_end" style="display:none;">'+
// '<p>'+'届出種別番号'+'</p>'+				
// '<p>'+'届出内容'+'</p>'+				
// '<p>'+'通信事業者種別番号'+'</p>'+				
// '<p>'+'誤差半径'+'</p>'+				
// '<p>'+'指令台番号'+'</p>'+				
// '</div>'+
// '<p>'+
// '  <a href="#" id="link_view2" onClick="toggle_view2();return false;">(more)</a>'+
// '  <a href="#" id="link_hidden2" onClick="toggle_hidden2();return false;" style="display:none;">(閉じる)</a>'+
// '</p>'+
// '<script language="JavaScript" type="text/javascript">'+
// '<!--'+
// 'var elem2_1 = document.getElementById("colom_end");'+
// 'var elem2_2 = document.getElementById("link_view2");'+
// 'var elem2_3 = document.getElementById("link_hidden2");'+
// 'function toggle_view2() {'+
// '  elem2_1.style.display = "";'+
// '  elem2_2.style.display = "none";'+
// '  elem2_3.style.display = "";'+
// '}'+
// 'function toggle_hidden2() {'+
// '  elem2_1.style.display = "none";'+
// '  elem2_2.style.display = "";'+
// '  elem2_3.style.display = "none";'+
// '}'+
// '-->'+
// '<' + '/script>'

// //車両ポップアップ
// export var contentCAR= 
// '<div id="colom_top">'+
//     '<p>'+'属性'+'</p>'+
//     '<p>'+'属性'+'</p>'+
//     '<p>'+'属性'+'</p>'+
//     '<p>'+'属性'+'</p>'+
// '</div>'+
// '<div id="colom_end" style="display:none;">'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '</div>'+
// '<p>'+
// '  <a href="#" id="link_view2" onClick="toggle_view2();return false;">(more)</a>'+
// '  <a href="#" id="link_hidden2" onClick="toggle_hidden2();return false;" style="display:none;">(閉じる)</a>'+
// '</p>'+
// '<script language="JavaScript" type="text/javascript">'+
// '<!--'+
// 'var elem2_1 = document.getElementById("colom_end");'+
// 'var elem2_2 = document.getElementById("link_view2");'+
// 'var elem2_3 = document.getElementById("link_hidden2");'+
// 'function toggle_view2() {'+
// '  elem2_1.style.display = "";'+
// '  elem2_2.style.display = "none";'+
// '  elem2_3.style.display = "";'+
// '}'+
// 'function toggle_hidden2() {'+
// '  elem2_1.style.display = "none";'+
// '  elem2_2.style.display = "";'+
// '  elem2_3.style.display = "none";'+
// '}'+
// '-->'+
// '<' + '/script>'

// //属性ポップアップ
// export var contentATTR= 
// '<div id="colom_top">'+
//     '<p>'+'属性'+'</p>'+
//     '<p>'+'属性'+'</p>'+
//     '<p>'+'属性'+'</p>'+
//     '<p>'+'属性'+'</p>'+
// '</div>'+
// '<div id="colom_end" style="display:none;">'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '  <p>ここを表示する</p>'+
// '</div>'+
// '<p>'+
// '  <a href="#" id="link_view2" onClick="toggle_view2();return false;">(more)</a>'+
// '  <a href="#" id="link_hidden2" onClick="toggle_hidden2();return false;" style="display:none;">(閉じる)</a>'+
// '</p>'+
// '<script language="JavaScript" type="text/javascript">'+
// '<!--'+
// 'var elem2_1 = document.getElementById("colom_end");'+
// 'var elem2_2 = document.getElementById("link_view2");'+
// 'var elem2_3 = document.getElementById("link_hidden2");'+
// 'function toggle_view2() {'+
// '  elem2_1.style.display = "";'+
// '  elem2_2.style.display = "none";'+
// '  elem2_3.style.display = "";'+
// '}'+
// 'function toggle_hidden2() {'+
// '  elem2_1.style.display = "none";'+
// '  elem2_2.style.display = "";'+
// '  elem2_3.style.display = "none";'+
// '}'+
// '-->'+
// '<' + '/script>'

