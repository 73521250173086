<!--マップコンポーネント-->
<template>
    <!--操作パネル-->
        <div style="left: 0px;">
            <ul class="list-group text-black">
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">■操作パネル</div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickSaigai">
                    ◎災害地点決定
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickSaigaiUpdate">
                    ◎災害区分変更
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickSaigaiDelete">
                   ◎災害地点非表示
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickMokuhyou">
                    ◎出動目標決定
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickTel">
                    ◎発信位置表示
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickTelDelete">
                    ◎発信位置非表示
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickAttr">
                    ◎属性位置表示
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickKinshi">
                    ◎車両位置表示
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickKinshiUpdate">
                    ◎車両動態変化
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickKinshiDelete">
                    ◎車両位置非表示
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" >
                    ×支援検索
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickShienComplete">
                    ◎支援終了
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickSaigaiComplete">
                    ◎災害終了
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <a href="#" class="tab__link text-indent p-1 block bg-blue-dark hover:bg-blue-darker no-underline text-black text-sm flex justify-between small" v-on:click="clickRouteSearch">
                    ◎ルート検索
                    </a>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">■試験用パラメータ</div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left">
                    <div id="input-form">
                <div class="pull-left text-black text-sm small">
                    端末の指令台番号：1000
                </div>
                <div class="pull-left text-black text-sm small">
                    指令台番号：<input v-model="testInfo.test_commandDskNo" placeholder="" style="width:80px;border: 1px solid;">
                </div>
                <div class="pull-left text-black text-sm small">
                    整番：<input v-model="testInfo.test_refno" placeholder="" style="width:100px;border: 1px solid;">
                </div>
                <div class="pull-left text-black text-sm small">
                    緯度：<input v-model="testInfo.test_lon" placeholder="" style="width:100px;border: 1px solid;">
                </div>
                <div class="pull-left text-black text-sm small">
                    経度：<input v-model="testInfo.test_lat" placeholder="" style="width:100px;border: 1px solid;">
                </div>
                <div class="pull-left text-black text-sm small">
                    災害区分：
                    <select v-model="testInfo.test_disasterClsNo">
                    <option v-for="(option, key) in testDisasterClsNoOptions" :key="key" v-bind:value="option.value">
                        {{ option.text }}
                    </option>
                    </select>
                </div>
                <div class="pull-left text-black text-sm small">
                    届出番号：<input v-model="testInfo.test_notifyNo" placeholder="" style="width:80px;border: 1px solid;">
                </div>
                
                <div class="pull-left text-black text-sm small">
                    通信業者：
                    <select v-model="testInfo.test_carrierTypeNoSelected">
                    <option v-for="(option, key) in testcarrierTypeNoOptions" :key="key" v-bind:value="option.value">
                        {{ option.text }}
                    </option>
                    </select>
                </div>
                <div class="pull-left text-black text-sm small">
                    車両：
                    <select v-model="testInfo.test_kinshiSelected">
                    <option v-for="(option, key) in testKinshiOptions" :key="key" v-bind:value="option.value">
                        {{ option.text }}
                    </option>
                    </select>
                </div>
                <div class="pull-left text-black text-sm small">
                    <button class="btn btn-primary btn-sm" style="width:150px;" v-on:click="testChangeRefno">整番変更</button>                    
                </div>
                <div class="pull-left text-black text-sm small">
                    <button class="btn btn-primary btn-sm" style="width:150px;" v-on:click="testChangeLonLat">緯度経度変更</button>                    
                </div>
                <div class="pull-left text-black text-sm small">
                    <button class="btn btn-primary btn-sm" style="width:150px;" v-on:click="testChange">値反映</button>                    
                </div>
                    </div>
                </div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">■地図からの通知内容</div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">method:{{testMapResult.method}}</div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">errorcode:{{testMapResult.errorcode}}</div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">result:{{testMapResult.result}}</div>
                </li>
                <li class="li-padding list-group-item-info text-left">
                <div class="pull-left text-black text-sm small">pointInfo:{{testMapResult.pointInfo}}</div>
                </li>
            </ul>              
        </div>      
</template>
<script>


    export default {
        name: 'Sireidai',
        created: function() {
            this.$emit('testChange',this.testInfo);
        },
        mounted: function () {
        },
        
        data () {
            return {
            //試験用の変数
            test_refno:9000,
            test_lon:139.767125,
            test_lat:35.681236,
            test_commandDskNo:1000,
            test_commandDskNo_org:1000,
            test_disasterClsNo:2,
            test_notifyNo:2000,
            testInfo : { 
                test_refno:9000,
                test_lon:139.767125,
                test_lat:35.681236,
                test_commandDskNo:1000,
                test_commandDskNo_org:1000,
                test_disasterClsNo:2,
                test_kinshiSelected: 'hashigo',
                test_carrierTypeNoSelected:'1',
                test_notifyNo:2000
            },

            testKinshiOptions: [
            { text: 'ハシゴ', value: 'hashigo' },
            { text: '救助', value: 'kyujyo' },
            { text: 'ポンプ', value: 'ponpu' },
            { text: 'タンク', value: 'tanku' }
            ],
            testcarrierTypeNoOptions: [
            { text: '固定電話', value: '1' },
            { text: '携帯電話', value: '2' },
            { text: 'HELPNET', value: '3' }
            ],
            testDisasterClsNoOptions: [
            { text: '火災', value: '0' },
            { text: '救急', value: '1' },
            { text: '警戒', value: '2' },
            { text: '救助', value: '3' },
            { text: '調査', value: '4' },
            { text: 'その他', value: '5' }
            ],

            testMapResult:'',

            lon: "",
            lat: "",
            saigai_lon: "",
            saigai_lat: "",
            mode: "",

            mapData: {
                lon:0.0,
                lat:0.0,
            },
            }
        },
        props: {
            // value: {
            //     type: Object,
            //     required: true,
            // },
        }, 
        computed: {
        },               
        methods: {
            testChangeRefno: function (event) {
                this.testInfo.test_refno = this.testInfo.test_refno + 1;
                this.$emit('testChange',this.testInfo);
            },            
            testChangeLonLat: function (event) {
                this.testInfo.test_lon = this.testInfo.test_lon + 0.01;
                this.testInfo.test_lat = this.testInfo.test_lat + 0.01;
                this.$emit('testChange',this.testInfo);
            },            
            testChange: function (event) {
                this.$emit('testChange',this.testInfo);
            },            
            //災害地点決定
            //lon                       経度                    数値型　x
            //lat                       緯度                    数値型  y
            //syubetsu                  種別                    文字:saigai　
            // refNo					整番					数値(整数)				
            // casePrcsStat				事案処理状態			数値(整数)				
            // disasterClsNo			災害区分番号			数値(整数)				
            // disasterPointAddr		災害地点住所			文字列				
            // disasterPointName		災害地点名称			文字列				
            clickSaigai: function () {
                var pointInfo = { 
                    lon: 137.0020882324639, lat: 35.200328003372306,syubetsu: "saigai", 
                    refNo:11111111,
                    casePrcsStat:0,
                    disasterClsNo:1,
                    disasterPointAddr:'東京都武蔵野市本町1-4-3 XXX-405',
                    disasterPointName:'田中太郎'
                    }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.lon=this.testInfo.test_lon;
                pointInfo.lat=this.testInfo.test_lat;
                pointInfo.disasterClsNo=this.testInfo.test_disasterClsNo;
                this.notifyAddPoint(pointInfo);
            },
            //災害区分変更
            clickSaigaiUpdate: function () {
                var pointInfo = { 
                    // lon: 137.0885882324639, lat: 35.200338003372306,
                    syubetsu: "saigai", 
                    refNo:123123123,
                    // casePrcsStat:0,
                    disasterClsNo:2,
                    // disasterPointAddr:'杉並区1-4-3',
                    // disasterPointName:'田中'
                }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.disasterClsNo= this.testInfo.test_disasterClsNo;
                this.notifyUpdatePoint(pointInfo);
            },
            //災害地点非表示
            clickSaigaiDelete: function () {
                var pointInfo = { 
                    // lon: 137.0020882324639, lat: 35.200328003372306,
                    syubetsu: "saigai", 
                    refNo:123123123,
                    // casePrcsStat:0,
                    // disasterClsNo:1,
                    // disasterPointAddr:'杉並区1-4-3',
                    // disasterPointName:'田中'
                }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                this.notifyDeletePoint(pointInfo);
            },
            //出動目標決定
            //lon                       経度                    数値型　
            //lat                       緯度                    数値型
            //syubetsu                  種別                    文字:mokuhyou　
            // refNo					整番					数値(整数)				
            // targetAddr				目標物住所				文字列				
            // targetName				目標物名称				文字列				
            clickMokuhyou: function () {
                var pointInfo = {
                    // lon: 137.0020882324639, lat: 35.200328003372306,syubetsu: "saigai", 
                    // lon: 137.0070882324639, lat: 35.205328003372306,syubetsu: "mokuhyou", //右上
                    //lon: 136.9900882324639, lat: 35.205328003372306,syubetsu: "mokuhyou", //左上
                    //lon: 137.0070882324639, lat: 35.195328003372306,syubetsu: "mokuhyou", //右下
                    lon: 136.9900882324639, lat: 35.195328003372306,syubetsu: "mokuhyou", //左下
                    //lon: 136.99144435629404, lat: 35.19492118823821,syubetsu: "mokuhyou", //矢印NG
                    refNo:123123123,
                    targetAddr:'杉並区3-6-7',
                    targetName:'コンビニ'}
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.lon=this.testInfo.test_lon;
                pointInfo.lat=this.testInfo.test_lat;
                this.notifyAddPoint(pointInfo);
            },
            //発信位置表示
            //lon                       経度                    数値型　
            //lat                       緯度                    数値型
            //syubetsu                  種別                    文字:tel　
            // notifyNo					届出番号				数値(整数)				
            // receptionTime			受付日時				文字列（日付と時間）				
            // refNo					整番					数値(整数)				
            // notifierAddr				届出者住所				文字列				
            // notifierName				届出者氏名				文字列				
            // telNo1					連絡先（市外局番）		文字列				
            // telNo2					連絡先（局番）			文字列				
            // telNo3					連絡先（番号）			文字列				
            // notifyTypeNo				届出種別番号			数値(整数)				
            // notifyContent			届出内容				文字列				
            // carrierTypeNo			通信事業者種別番号		数値(整数)				
            // errorRadius				誤差半径				数値				
            // commandDskNo				指令台番号				数値(整数)				
            clickTel: function () {
                var pointInfo = { 
                    lon: 137.0020882324639, lat: 35.220328003372306,syubetsu: "tel", 
                    notifyNo:123,
                    receptionTime:'2020/1/3',
                    refNo:123123123,
                    notifierAddr:'武蔵野市1-3-4',
                    notifierName:'山田',
                    telNo1:'03',
                    telNo2:'4567',
                    telNo3:'3333',
                    notifyTypeNo:1,
                    notifyContent:'あいうえお',
                    carrierTypeNo:2,
                    errorRadius:200,
                    commandDskNo:12345,
                    }
                //試験用パラメータセット
                pointInfo.notifyNo=this.testInfo.test_notifyNo;
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.lon=this.testInfo.test_lon;
                pointInfo.lat=this.testInfo.test_lat;
                pointInfo.commandDskNo=this.testInfo.test_commandDskNo;
                pointInfo.carrierTypeNo=this.testInfo.test_carrierTypeNoSelected
                this.notifyAddPoint(pointInfo);
            },
            //発信位置非表示
            clickTelDelete: function () {
                var pointInfo = { 
                    // lon: 137.0020882324639, lat: 35.220328003372306,
                    syubetsu: "tel", 
                    notifyNo:123,
                    // receptionTime:'2020/1/3',
                    // refNo:123123123,
                    // notifierAddr:'武蔵野市1-3-4',
                    // notifierName:'山田',
                    // telNo1:'03',
                    // telNo2:'4567',
                    // telNo3:'3333',
                    // notifyTypeNo:1,
                    // notifyContent:'あいうえお',
                    // carrierTypeNo:1,
                    // radius:200,
                    // commandDskNo:12345,
                    }
                //試験用パラメータセット
                pointInfo.notifyNo=this.testInfo.test_notifyNo;
                this.notifyDeletePoint(pointInfo);
            },
            //車両位置表示
            //lon                       経度                    数値型　
            //lat                       緯度                    数値型
            //syubetsu                  種別                    文字:car　
            // refNo                    車両番号                数値(整数)
            // name                     車両名称                文字("hashigo","kyujyo","ponpu","tanku")
            // doutai                   車両動態                数値(整数) 現場到着、放水開始
            // address                  住所                    文字
            clickKinshi: function () {
                var pointInfo = { 
                    lon: 137.0870882324639, lat: 35.235328003372306, 
                    syubetsu: "Kinshi" ,
                    refNo:4321322,
                    name:'kyujyo',
                    doutai:'現場到着',
                    address:'杉並区4-6-7'}
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.lon=this.testInfo.test_lon;
                pointInfo.lat=this.testInfo.test_lat;
                pointInfo.name=this.testInfo.test_KinshiSelected;
                this.notifyAddPoint(pointInfo);
            },
            //車両動態変化
            clickKinshiUpdate: function () {
                var pointInfo = { 
                    lon: 137.0880882324639, lat: 35.245328003372306, 
                    syubetsu: "Kinshi" ,
                    refNo:4321322,
                    // name:'kyujyo',
                    // doutai:'現場到着',
                    // address:'杉並区4-6-7'
                    }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.lon=this.testInfo.test_lon;
                pointInfo.lat=this.testInfo.test_lat;
                // pointInfo.name=this.testInfo.test_KinshiSelected;
                this.notifyUpdatePoint(pointInfo);
            },
            //車両非表示
            clickkKinshiDelete: function () {
                var pointInfo = { 
                    // lon: 137.0870882324639, lat: 35.235328003372306, 
                    syubetsu: "Kinshi" ,
                    refNo:4321322,
                    // name:'kyujyo',
                    // doutai:'現場到着',
                    // address:'杉並区4-6-7'
                    }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                this.notifyDeletePoint(pointInfo);
            },
            //属性位置表示
            clickAttr: function () {
                var pointInfo ={ 
                    refNo: 51, lon: 137.0920882324639, lat: 35.200328003372306 , syubetsu: "attr" }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                pointInfo.lon=this.testInfo.test_lon;
                pointInfo.lat=this.testInfo.test_lat;
                this.notifyAddPoint(pointInfo);
            },
            //支援終了
            clickShienComplete: function () {
                var pointInfo = { 
                    // lon: 137.0020882324639, lat: 35.200328003372306,
                    syubetsu: "saigai", 
                    refNo:123123123,
                    // casePrcsStat:1,
                    // disasterClsNo:'1',
                    // disasterPointAddr:'杉並区1-4-3',
                    // disasterPointName:'田中'
                    }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                this.notifyShienComplete(pointInfo);
            },
            //災害終了
            clickSaigaiComplete: function () {
                var pointInfo = { 
                    // lon: 137.0020882324639, lat: 35.200328003372306,
                    syubetsu: "saigai", 
                    refNo:123123123,
                    // casePrcsStat:1,
                    // disasterClsNo:'1',
                    // disasterPointAddr:'杉並区1-4-3',
                    // disasterPointName:'田中'
                    }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                this.notifySaigaiComplete(pointInfo);
            },
            //ルート検索
            clickRouteSearch: function () {
                var pointInfo = { 
                    // lon: 137.0020882324639, lat: 35.200328003372306,
                    syubetsu: "saigai", 
                    refNo:11111111,
                    // casePrcsStat:0,
                    // disasterClsNo:1,
                    // disasterPointAddr:'杉並区1-4-3',
                    // disasterPointName:'田中'
                    }
                //試験用パラメータセット
                pointInfo.refNo=this.testInfo.test_refno;
                this.notifyRouteSearch(pointInfo);
            },

            //地図へ緯度経度ポイントの追加を通知する
            notifyAddPoint: function(pointInfo) {
                console.debug("+pointInfo.syubetsu:"+pointInfo.syubetsu);
                var callback = this.callbackMap;
                this.$emit('notifyAddPoint', pointInfo);

            },
            //地図へ緯度経度ポイントの変更を通知する
            notifyUpdatePoint: function(pointInfo) {
                console.debug("+pointInfo.syubetsu:"+pointInfo.syubetsu);
                this.$emit('notifyUpdatePoint', pointInfo);

            },
            //地図へ緯度経度ポイントの削除を通知する
            notifyDeletePoint: function(pointInfo) {
                console.debug("+pointInfo.syubetsu:"+pointInfo.syubetsu);
                this.$emit('notifyDeletePoint', pointInfo);
            },
            //支援終了
            notifyShienComplete: function(pointInfo) {
                console.debug("+pointInfo.syubetsu:"+pointInfo.syubetsu);
                this.$emit('notifyShienComplete', pointInfo);
            },
            //災害終了
            notifySaigaiComplete: function(pointInfo) {
                console.debug("+pointInfo.syubetsu:"+pointInfo.syubetsu);
                this.$emit('notifySaigaiComplete', pointInfo);
            },
            //ルート検索
            notifyRouteSearch: function(pointInfo) {
                console.debug("+pointInfo.syubetsu:"+pointInfo.syubetsu);
                this.$emit('notifyRouteSearch', pointInfo);
            },
            //地図からのCALLBACKを受け取る
            callbackMap: function(callbackMapInfo) {
                console.debug('method:' + callbackMapInfo.method);
                console.debug('errorcode:' + callbackMapInfo.errorcode);
                console.debug('result:' + callbackMapInfo.result);
                console.debug(callbackMapInfo.pointInfo);
                this.testMapResult=callbackMapInfo;


            },
            // //地図へ目標ポイントと災害ポイントを通知する
            // notifyMokuhyou: function(id,type,syubetsu,lon,lat,saigai_lon,saigai_lat) {
            //     this.$emit('notifyMokuhyou', {
            //         lon: lon,
            //         lat: lat,

            //         saigai_lat: saigai_lat,
            //         id: id,
            //         type: type,
            //         syubetsu: syubetsu
            //     });                
            // },
            //地図からのイベント通知を受け取る
            // notifyMap: function(eventMapInfo) {
            //     console.debug('syubetsu:' + eventMapInfo.syubetsu);
            //     console.debug('mode:' + eventMapInfo.mode);
            //     console.debug('refNo:' + eventMapInfo.refNo);
            //     alert('地図から'+ eventMapInfo.syubetsu +'の'+ eventMapInfo.refNo+'の'+eventMapInfo.mode+'を受信しました');
            // },

        }
    }

</script>

<style>

</style>

