//$routerで呼び出せないのでインポート！！
import router from '../../route.js'

export default {
    namespaced: true,
    state: {
      postingdate: '',
      staffcd: '',          //過去履歴表示の時のみセット
      nowstaffcd: '',       //イントラからログイン時のみセット
      stafflist: [],      //配布状況表示の時のみセット
      geojson: [],          //配布エリア
      prohibitedpoints: [], //配布禁止ポイント
      mapmode: '',          //地図表示モード:Rireki：過去履歴,DeliveryStatus：配布状況
    },
    mutations: {
      create (state, data) {
        console.log('=====staffのステート==================');
        console.log('data.staffcd:'+data.staffcd);
        console.log('data.nowstaffcd:'+data.nowstaffcd);
        console.log('data.stafflist:'+data.stafflist);
        console.log('data.postingdate:'+data.postingdate);
        console.log('data.geojson:'+data.geojson);
        console.log(data.geojson);
        console.log('data.prohibitedpoints:'+data.prohibitedpoints);
        console.log('data.mapmode:'+data.mapmode);
        console.log('===================================');
        state.mapmode = data.mapmode;
        state.staffcd = data.staffcd;
        state.nowstaffcd = data.nowstaffcd;
        state.stafflist = data.stafflist;
        state.postingdate = data.postingdate;
        state.geojson = data.geojson;
        state.prohibitedpoints = data.prohibitedpoints;
        let propertyArray = Object.entries(data);
        propertyArray.forEach(function(element){
          console.log(element);
        });
          
      },
      destroy (state) {
        state.mapmode = '';
        state.staffcd = '';
        state.nowstaffcd = '';
        state.stafflist = [];
        state.postingdate = '';
        state.geojson = [];
        state.prohibitedpoints = [];
      }
    },
    actions: {
      //=================================================
      //使用停止：20220630 createlistを使用する
      //スタッフCDから配布エリアを取得（過去履歴表示で使用、単一担当）
      //=================================================
      create ({ commit, dispatch }, data) {
        dispatch(
          'http/post',
          { url: '/maps/areabystaffcd', data, error: '配布エリア取得エラー' },
          { root: true }
        ).then((res) => {
        //).then(function(res) {
            console.log('#####areabystaffcd############');
            console.log(res.status);
            console.log('data.staffcd:'+data.staffcd);
            console.log(data.stafflist);
            console.log('data.stafflist:'+data.stafflist);
            console.log('#################');
            //配布エリアが登録されていない場合はエラーメッセージを表示する
            if (res.status == 204){
              //エラーメッセージセットしダイアログ表示　※呼び出し元でストア変数errorを監視してダイアログ表示
              // this.$modal.show('MessageDialog');
              // this.$root.$emit('showLogout');
              // this.$root.show('MessageDialog');
              dispatch(
                'message/create',
                {errorinfo:{error: '配布エリアが登録されていません',redirect: ''} },
                { root: true }
              );
            }
            
            //配布エリア取得成功
            if (res.status == 200){
              //ステートにセーブ
              console.log('##配布エリアをステートにセーブ##');
              //地図表示モードをセット
              res.data.mapmode = data.mapmode;
              res.data.staffcd = data.staffcd;
              res.data.stafflist = data.stafflist;
              commit('create', res.data);

              //地図画面遷移
              router.push('/AppMap', () => {});
              // router.push({name:'AppMap', params: {reloadflg: true}});
            }
          }) 
          // ).then(function(res) {
          //   console.log('#################');
          //   console.log(res.status);
          //   console.log('#################');
          //   //TODO 配布エリアが登録されていない場合はエラーメッセージを表示する
          //   if (res.status == 204){
          //     that.$modal.show('MessageDialog');
          //   }
          //   //配布エリア取得成功
          //   if (res.status == 200){
          //     //ステートにセーブ
          //     console.log('##配布エリアをステートにセーブ##');
          //     commit('create', res.data);
          //     //地図画面遷移
          //     router.push('/AppMap', () => {});
          //   }
          // }) 
          .catch((err) => {
            console.log(err);
            if (err.response.status == 401){
              //ログイン画面遷移
              dispatch(
                'message/create',
                {errorinfo:{error: '再ログインしてください',redirect: 'login'} },
                { root: true }
              );
              console.log('#################');
              console.log(err.response.status);
              console.log('#################');
              //認証クリア
              //ログイン画面遷移 this.gvLogout()がcallできないのでリダイレクトしてログアウトする
              //router.push('/Redirect', () => {});
            }
          })
      },
      //=================================================
      //スタッフCDリストから配布エリアを取得
      //配布状況表示で使用、複数担当者
      //過去履歴表示で使用、単一担当
      //=================================================
      createlist ({ commit, dispatch }, data) {
        dispatch(
          'http/post',
          { url: '/maps/areabystaffcdlist', data, error: '配布エリア取得エラー' },
          { root: true }
        ).then((res) => {
        //).then(function(res) {
            console.log('#####areabystaffcdlist############');
            console.log('status:'+res.status);
            console.log('data.postingdate:'+data.postingdate);
            console.log('data.staffcd:'+data.staffcd);
            console.log('data.nowstaffcd:'+data.nowstaffcd);
            console.log('data.stafflist:'+data.stafflist);
            console.log('data.geojson:'+data.geojson);
            console.log('data.prohibitedpoints:'+data.prohibitedpoints);
            console.log('data.deliveries:'+data.deliveries);
            console.log('#################');
            //配布エリアが登録されていない場合はエラーメッセージを表示する
            if (res.status == 204){
              //エラーメッセージセットしダイアログ表示　※呼び出し元でストア変数errorを監視してダイアログ表示
              // this.$modal.show('MessageDialog');
              // this.$root.$emit('showLogout');
              // this.$root.show('MessageDialog');
              dispatch(
                'message/create',
                {errorinfo:{error: '配布エリアが登録されていません',redirect: ''} },
                { root: true }
              );
            }
            
            //配布エリア取得成功
            if (res.status == 200){
              //ステートにセーブ
              console.log('##配布エリアをステートにセーブ##');
              //地図表示モードをセット
              res.data.mapmode = data.mapmode;
              res.data.staffcd = data.staffcd;
              res.data.nowstaffcd = data.nowstaffcd;
              res.data.stafflist = data.stafflist;
              commit('create', res.data);
              //地図画面遷移
              if ((data.nowstaffcd =='')||(data.nowstaffcd === undefined)){
                //20230619
                router.push('/AppMap', () => {});
                console.log('##data.nowstaffcd undefined##');
              }else{
                //20230619
                router.push(`/AppMap/${data.nowstaffcd}`, () => {})
                console.log(`##/AppMap/${data.nowstaffcd}`);
              }
              // router.push({name:'AppMap', params: {reloadflg: true}});
            }
          }) 
          .catch((err) => {
            console.log(err);
            if (err.response.status == 401){
              //ログイン画面遷移
              dispatch(
                'message/create',
                {errorinfo:{error: '再ログインしてください',redirect: 'login'} },
                { root: true }
              );
              console.log('#################');
              console.log(err.response.status);
              console.log('#################');
            }
          })
      },

      // create(context, payload) {
      //   console.log(payload);
      //   context.commit('create', payload) //ステートにセーブ
      // },
      destroy(context) {
        context.commit('destroy')
      },
    }
  }